@import "../../../App.scss";


.talent-search-preview-control{
    width: auto;
    height: 99vh;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md){
        height: 100vh;
    }

    .feature-credit-usage-main-control{
        display: flex;
        margin-bottom: 12px;

        @include media-breakpoint-down(sm){
            flex-direction: column;
            margin-bottom: 12px;
        }
    }

    .feature-credit-usage-control{

        display: flex;
        margin-top: 16px;
        margin-left: 30px;

        .feature-credit-usage-icon{
            font-size: 16px;
            color: rgb(255, 183, 0);
        }

        .feature-credit-usage-text{
            font-size: 14px;
            color: var(--text-black-color);
            margin-left: 8px;
            font-family: $fontRegularLINE;
        }
        .feature-credit-usage-text-bold{
            font-size: 14px;
            color: var(--text-black-color);
            margin-left: 8px;
            font-family: $fontMedium;
        }
    }
    .button-history-talent-search{
        background-color: var(--button-primary);
        min-width: 300px;
        height: 41px;
        border-radius: 4px;
        color: var(--text-white-color);
        font-size: 14px;
        font-family: $fontMedium;
        margin-left: auto;
        border: none;
       

        &:hover{
            background-color: var(--button-primary);
            box-shadow:  0px 0px 5px var(--border-input);
        }

        @include media-breakpoint-down(md){
            margin-left: auto;
            margin-right: auto;
        }
    }
   
}