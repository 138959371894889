@import "../../App.scss";
.header-menu-text{
    font-size: 14px;
    color: var(--text-black-color);
    font-family: $fontMedium;
}
.header-control{
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    height: 100%;
   
    z-index: 2;

    .MuiMenu-paper{
        font-family: $fontMedium !important;
    }

    .avatar-control{
        width: auto;
        padding: 4px 12px;
        border-radius: 8px;
        height: 55px;
        display: flex;

        @include media-breakpoint-down(md){
            border: none;
        }
        @include media-breakpoint-down(sm){
            border: none;
            padding: 4px 12px;
        }
        .avatar-icon{
            margin-right: auto;
        }

        .avatar-menu{
            font-size: 25px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;

            @include media-breakpoint-down(md){
                display: none;
            }
        }
    }
   
    .header-app-bar{
        display: grid;
        padding: 0px 5%;
        grid-template-columns: 50% 50%;
        background-color: black;
        height: 60px;

        @include media-breakpoint-down(md){
            padding: 4px 1%;
            grid-template-columns: 30% 70%;
        }
        @include media-breakpoint-down(sm){
            padding: 4px 12px;
            grid-template-columns: 20% 80%;
            height: 45px !important;
        }

        .MuiToolbar-root{
            height: 60px;
            min-height: 61px !important;
            @include media-breakpoint-down(sm){
                height: 40px;
                min-height: 41px !important;
            }
        }
        .header-app-bar-logo-company{
            width: auto;
            height: 41px;
            object-fit: contain;

            @include media-breakpoint-down(md){
                width: 20px;
            }
        }

        .header-app-right-menu{
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            height: 60px !important;

            @include media-breakpoint-down(sm){
                height: 41px !important;
               
            }

            .MuiBox-root{
                height: 60px !important;
                padding: 0px;
                @include media-breakpoint-down(sm){
                    height: 35px !important;
                    padding: 0px;
                }
            }
        }

      
        
    }

    .header-credit-control{
        cursor: pointer;
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: 26px;
        border: 2px solid white;
        padding: 8px 24px;
        margin-right: 14px;

        @include media-breakpoint-down(sm){
            height: 31px;
            padding: 2px 24px;
        }

        .header-credit-coin{
            font-size: 15px;
            color: var(--pro-color);
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(sm){
                font-size: 11px;
            }
    
        }

        .header-credit-text{
            margin-left: 16px;
            font-size: 14px;
            color: white;
            font-family: $fontMedium;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(sm){
                font-size: 11px;
            }
        }
    }
}