@import "../../../../App.scss";



.template-0-main{

    display: block;
    margin-left: auto;
    margin-right: auto;

   

    .template-0-logo-comp{
        width: 200px;
        height: auto;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }

    .template-0-divider{
       
        margin-bottom: 14px;
        width: 100%;
        border-width: 4px;
        border-style: solid;
    }

    .template-0-content-grid{
        display: grid;
        grid-template-columns: 60% 40%;
        margin-top: 34px;
    }
    .template-0-content-left{
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .template-0-content-right{
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .template-0-title-section{
        font-size: 24px;
        font-family: $fontMedium;
    }
    .template-0-cover-letter{
        font-size: 14px;
        font-family: $fontRegular;
        font-style: italic;
        text-align: center;

    }
    .template-0-experience-control{
        display: flex;
        flex-direction: column;
      

        .template-0-experience-title{
            font-size: 24px;
            font-family: $fontMedium;
        }
        .template-0-experience-item{
            display: flex;
            flex-direction: column;

            .template-0-language-grid{
                display: grid;
                grid-template-columns: 25% 25% 25% 25%;

                    .MuiTypography-root{
                        margin-right: 6px;
                        font-size: 14px;
                        font-family: $fontRegular;
                    }

                    .MuiSvgIcon-root{
                        width: 0.7em;
                        height: 0.7em;
                    }

                    .Mui-disabled{
                        color: black;
                    }
                    
                   

            }
            
            .template-0-experience-school-title{
                font-size: 14px;
                font-family: $fontMedium;
                color: black;
            }
            .template-0-experience-job-title{
                font-size: 17px;
                font-family: $fontMedium;
                color: black;
            }
            .template-0-experience-item-divider{
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .template-0-experience-location-control{
                display: flex;
                margin-top: 4px;
                .template-0-experience-location-icon{
                    font-size: 14px;
                    margin-top: auto;
                    margin-bottom: auto;
                }
                .template-0-experience-location-text{
                    margin-left: 8px;
                    font-size: 13px;
                    margin-top: auto;
                    margin-bottom: auto;
                    font-style: italic;
                    font-family: $fontRegular;
                }
            }
            .template-0-experience-responsibility-control{
                display: flex;
                margin-top: 4px;
              
                .template-0-experience-responsibility-text{
                    margin-left: 8px;
                    font-size: 13px;
                    margin-top: auto;
                    margin-bottom: auto;
                    font-family: $fontRegular;
                }
            }
            .template-0-experience-date-control{
                display: flex;
                .template-0-experience-date-icon{
                    font-size: 14px;
                    margin-top: auto;
                    margin-bottom: auto;
                }
                .template-0-experience-date-text{
                    margin-left: 8px;
                    font-size: 14px;
                    margin-top: auto;
                    margin-bottom: auto;
                    font-family: $fontRegular;
                }

            }
        }
    }

    .template-0-about-control{
        width: 100%;
        height: auto;
        display: flex;
        margin-top: 24px;
        flex-direction: column;

        .template-0-name-text{
            font-size: 24px;
            font-family: $fontMedium;
            color: black;            
        }

        .template-0-position-text{
            font-size: 16px;
            font-family: $fontMedium;
        }

        .template-0-contact-control{
            display: flex;
            margin-top: 6px;

            .template-0-contact-item{
                display: flex;
                margin-right: 8px;
                height: 25px;

                .template-0-contact-icon{
                    font-size: 14px;
                    margin-top: auto;
                    margin-bottom: auto;
                  
                }

                .template-0-contact-text{
                    margin-left: 6px;
                    font-size: 14px;
                    margin-top: auto;
                    margin-bottom: auto;
                    font-family: $fontRegular;
                }
            }
        }
    }

   
}