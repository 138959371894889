@import "../App.scss";

.candidate-modal-main{

    
    .modal-dialog {
        max-width: 50% !important;
        width: 50% !important;
        height: 90vh !important;
        margin-bottom: 5% !important;

        @include media-breakpoint-down(md){
            width: 100% !important;
            max-width: 100% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content{
        height: 90vh !important;
    }
    .modal-body{
        overflow: scroll;
        flex-wrap: wrap;
    }

    .candidate-modal-bookmark-loading{
        cursor: pointer;
        color: var(--primary-color);
    }

    .candidate-modal-detail-control{
        display: flex;
        flex-direction: column;
        padding: 16px;
        height: 100%;
      

        .candidate-top-profile-control{
            display: flex;

            .candidate-profile-candidate-id-control{
                display: flex;
                flex-direction: column;
            }
            .candidate-profile-candidate-icon{
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin-right: auto;
                color: var(--primary-color);
            }
            .candidate-profile-candidate-id{
                font-size: 13px;
                color: var(--text-black-color);
                font-family: $fontMedium;
            }
            .candidate-profile-last-update{
                font-size: 13px;
                color: var(--text-gray-color);
                font-family: $fontRegular;
                margin-left: auto;
                text-align: right;
            }
        }

        .canidate-profile-control{
            display: flex;
            flex-direction: column;
         

           

            .candidate-profile-img{
                width: 100px;
                height: 100px;
                object-fit: contain;
                margin-left: auto;
                border-radius: 50%;
                margin-right: auto;
            }
            .candidate-profile-img-default{
                font-size: 60px;
                color: var(--primary-color);
                margin-left: auto;
                margin-right: auto;
            }

            .candidate-profile-name{
                margin-top: 16px;
                font-size: 16px;
                color: var(--text-black-color);
                font-family: $fontMedium;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }
            .candidate-profile-desc{
                font-size: 14px;
                color: var(--text-black-color);
                font-family: $fontMedium;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .candidate-view-resume-button{
            margin-left: auto;
            margin-right: auto;
            background-color: var(--button-primary);
            margin-top: 12px;
            border-radius: 8px;
            border: none;
            font-family: $fontMedium;
            font-size: 14px;
            color: var(--text-button-color);
            padding: 8px 24px;
        }

        .candidate-view-title{
            font-size: 14px;
            color: var(--text-black-color);
            font-family: $fontMedium;
        }
        .candidate-info-control{
            min-height: 300px;
            width: 100%;
            display: flex;
    
            .candidate-info-control-flex{
                margin: auto;
                display: flex;
                flex-direction: column;
            }
    
            .candidate-info-control-text{
                font-size: 16px;
                font-family: $fontRegular;
                color: var(--text-black-color);
                margin-left: auto;
                margin-right: auto;
            }
    
            .candidate-info-control-add-button{
                margin-top: 16px;
                width: 200px;
                height: 41px;
                color: white;
                font-family: $fontMedium;
                font-size: 14px;
            }
        }
       
        .candidate-info-timeline-control {
            margin-top: 12px;
            display: grid;
            grid-template-columns: 10% 90%;
           
    
            .candidate-info-timeline-line-control {
                display: flex;
                flex-direction: column;
                padding-top: 4px;
    
                .candidate-info-timeline-dot {
                    width: 10px;
                    height: 10px;
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 50%;
                    background-color: var(--border-input);
                }
    
                .candidate-info-timeline-line-vertical {
                    margin-top: 4px;
                    margin-bottom: 2px;
                    width: 2px;
                    height: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    opacity: 0.5;
                    background-color: var(--border-input);
                }
            }
    
            .candidate-info-timeline-desc-control {
    
                display: flex;
                flex-direction: column;
                padding-left: 16px;
                padding-right: 16px;
                margin-bottom: 16px;
    
                .candidate-info-timeline-desc-header {
                    font-size: 13px;
                    font-family: $fontMedium;
                    color: var(--text-black-color);
                }
    
                .candidate-info-timeline-desc-info-control {
                    margin-top: 4px;
                    margin-left: 8px;
                    display: flex;
                    flex-direction: column;
    
                    .candidate-info-timeline-desc-info-title {
                        font-size: 13px;
                        font-family: $fontRegular;
                        color: var(--text-gray-color);
                        margin-right: 4px;
                    }
                }

                .candidate-info-timeline-desc-view-more{
                    font-size: 14px;
                    color: var(--primary-blue-color);
                    font-family: $fontRegular;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    
    }
    .candidate-loading-control {
        height: 500px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;

        .candidate-loading-progress-control {
            margin: auto;
            display: flex;
            flex-direction: column;
        }

        .candidate-loading-progress {
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
            color: var(--primary-color);
        }

       


        .candidate-loading-text {
            font-size: 15px;
            color: var(--text-black-color);
            font-family: $fontRegular;
            margin-top: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .candidate-error-divider{
        
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .candidate-error-control{
        display: flex;
        flex-direction: column;
        margin-top: 36px;

        .candidate-error-icon{
            font-size: 60px;
            color: var(--primary-color);
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 16px;
        }

        .candidate-error-text{
            font-size: 18x;
            color: var(--primary-color);
            margin-left: auto;
            margin-right: auto;
            font-family: $fontMedium;
            margin-bottom: 36px;
        }

        .candidate-error-close{
            cursor: pointer;
            font-size: 18x;
            margin-top: 36px;
            color: var(--text-black-color);
            margin-left: auto;
            margin-right: auto;
            text-decoration: underline;
            font-family: $fontMedium;
        }
    }
}