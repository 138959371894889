@import "../../../App.scss";

.history-candidate-main {
    width: 100%;
    display: flex;

    @include media-breakpoint-down(md){
        width: auto;
    }

    .history-candidate-main-control {
        width: 100%;
        display: flex;
        flex-direction: column;
        .MuiTableCell-head{
            font-family: $fontMedium !important;
        }
        .MuiTableCell-body {
            font-family: $fontRegular !important;
            color: var(--text-black-color);
        }
        .history-candidate-main-table-control{
            width: 100%;
            

            @include media-breakpoint-down(md){
                width: 100%;
            }
        }

        .history-candidate-no-result{
            height: 90vh;
            min-height: 90vh;
            width: 100%;
            display: flex;

            .history-candidate-no-result-control{
                margin: auto;
                display: flex;
                flex-direction: column;

                .history-candidate-no-result-icon{
                    width: 30px;
                    height: 30px;
                    color: var(--text-gray-color);
                    margin-left: auto;
                    margin-right: auto;
                }

                .history-candidate-no-result-text{
                    margin-top: 16px;
                    font-size: 14px;
                    color: var(--text-gray-color);
                    font-family: $fontRegular;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .history-candidate-loading-control {
            height: 500px;
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: auto;
    
            .history-candidate-loading-progress-control {
                margin: auto;
                display: flex;
                flex-direction: column;
            }
    
            .history-candidate-loading-progress {
                width: 30px;
                height: 30px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
                color: var(--primary-color);
            }
            .history-candidate-loading-text {
                font-size: 15px;
                color: var(--text-black-color);
                font-family: $fontRegular;
                margin-top: 16px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .history-candidate-button-view{
            cursor: pointer;
            width: 70%;
            height: 40px;
            font-size: 12px;
            color: var(--text-button-color);
            background-color: var(--button-primary);
            margin: auto;
            border: none;
            border-radius: 8px;
            font-family: $fontMedium;

            &:hover{
                text-decoration: underline;
            }

            @include media-breakpoint-down(md){
                width: 100%;
            }
        }
    }
}