@import "../../../App.scss";

.result-clinic-page{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    

   

    @include media-breakpoint-down(md){
        width: 100%;
        display: block;
        min-height: 100vh;
        margin-bottom: 48px;
        background-color: white;
        border-radius: 15px;
    }

    .result-type-text{
        font-size: 16px;
        font-family: $fontMedium;
    }


    .result-page-footer{
        margin-bottom: 16px;
        margin-top: auto;
        width: 100%;
        display: flex;
        flex-direction: column;

        .result-page-img-logo{
            width: 160px;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .result-page-img-power-by{
            width: auto;
            height: 25px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .result-close-button {
        cursor: pointer;
        position: absolute;
        font-size: 24px;
        margin-left: auto;
        margin-top: 12px;
        margin-right: 12px;
        z-index: 2;
        right: 0;
    }
    .result-right-control{
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        
        height: auto;
        border-radius: 12px;
        border:none;
        padding: 1px;
        position: relative;
        box-shadow: 0px 0px 12px var(--brown-color);

        @include media-breakpoint-down(xl){
            width: 550px;
        }

        @include media-breakpoint-down(md){
            margin-top: 15%;
            max-height: auto !important;
            width: 100%;
            box-shadow: none;
        }

        @include media-breakpoint-down(sm){
            margin-top: 18%;
            padding: 16px;
        }

        .result-paster-img{
            margin-left: auto;
            margin-right: auto;
            width: 55%;
            left: 0;
            right: 0;
            height: 100px;
            object-fit: contain;
            position: absolute;
            margin-top: -15%;

            @include media-breakpoint-down(xl){
                width: 50%;
                margin-top: -15%;
            }

            @include media-breakpoint-down(md){
                margin-top: -16%;
                z-index: 2;
            }
    
            @include media-breakpoint-down(sm){
                margin-top: -20%;
               
            }
        }

        .result-hr-img{
            margin-left: auto;
            width: auto;
            left: 0;
            right: 0;
            height: 23%;
            object-fit: contain;
            position: absolute;
            margin-top: auto;
            bottom: 0;
            margin-bottom: -10%;
        }


        .result-right-white-bg{
            background-color: white;
            border-radius: 12px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 16px;
            position: relative;
            padding-right: 16px;
            padding-top: 14px;
            padding-bottom: 15px;

            @include media-breakpoint-down(md){
                padding: 16px 0px;
                height: auto;
            }

            .result-right-text-control{
                border: 2px solid #888;
                border-radius: 12px;
                padding: 24px 12px;
                height: 100%;
                margin-top: 16px;
                font-size: 13px;
                font-family: $fontRegular;
                color: var(--text-black-color);
                overflow-wrap: break-word;

                @include media-breakpoint-down(md){
                    font-size: 12px;
                }
                
            }

            
        }
    }
    .social-share-control{
    
        .social-share-img{
            width: 100%;
            height: auto;
            object-fit: contain;
        }
        .social-grid{
            display: grid;
            width: 100%;
            grid-template-columns: 50% 50%;
    
            .img-icon-share{
                width: 40px;
                height: 40px;
                margin-left: auto;
                margin-right: auto;
                object-fit: contain;
              
                @include media-breakpoint-down(md){
                    width: 40px;
                    height: 40px;
                    object-fit: contain;
                }
            }
        }
    
        .close-button{
            width: 100%;
            height: 41px;
            background-color: var(--button-primary);
            color: var(--text-button-color);
            font-size: 24px;
            border: none;
            font-family: $fontMedium;
            border-radius: 8px;
            margin-top: 24px;
        }
    
    }
    .result-share-control{
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12px;
        display: flex;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }

        .result-share-text{
            font-size: 14px;
            color: var(--text-black-color);
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
            font-family: $fontMedium;
        }
        .result-button-share{
            background-color: var(--primary-color);
            border-radius: 24px;
            border:none;
            margin-left: 4px;
            margin-right: 4px;
            min-width: 150px;
            height: 45px;
            font-size: 14px;
            color: white;
            margin-top: auto;
            margin-bottom: auto;
            font-family: $fontMedium;

            @include media-breakpoint-down(md){
                font-size: 12px;
                height: 40px;
                margin-bottom: 12px;
            }
        }
      
    }
    .result-not-found-control{
        display: flex;
        flex-direction: column;
        margin: auto;

        .result-not-found-img{
            width: 120px;
            height: 120px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .result-not-found-text{
            text-align: center;
            margin-top: 24px;
            font-size: 17px;
            color: var(--text-black-color);
            font-family: $fontMedium;
        }
    }

    .result-button-back-to-top{
        min-width: 150px;
        margin-top: 24px;
        display: none;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        border: none;
        height: 45px;
        color: var(--primary-color);
        font-family: $fontMedium;
        font-size: 16px;
        border-radius: 24px;

        @include media-breakpoint-down(md){
            display: block;
            font-size: 12px;
            height: 40px;
        }
    }

      /* The typing effect */
      @keyframes typing {
        from { width: 0 }
        to { width: 100% }
      }
      
      /* The typewriter cursor effect */
      @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: orange; }
      }
}