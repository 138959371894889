@import "../../../../App.scss";

.talent-sauce-footer-main{

    width: 100vw;
    height: 80px;
    background-color: black;
    display: flex;
    padding: 14px 5%;
    z-index: 4;

    @include media-breakpoint-down(md){
        flex-direction: column;
        height: auto;
        min-height: 100px;
    }

    .talent-sauce-footer-logo-left{
        cursor: pointer;
        width: auto;
        height: 30px;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: auto;
        color: var(--text-white-color);

        @include media-breakpoint-down(md){
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .talent-sauce-footer-terms-control{
        margin-left: auto;
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        @include media-breakpoint-down(md){
            margin-left: auto;
            margin-right: auto;
            flex-direction: column;
            margin-top: 36px;
        }

        .talent-sauce-footer-terms-text{
            cursor: pointer;
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--text-white-color);
            margin-right: 16px;
            margin-left: 16px;

            @include media-breakpoint-down(md){
                font-size: 14px;
                font-family: $fontRegularLINE;
                margin-left: auto;
                margin-right: auto;
                margin-top: 6px;
            }
        }
    }

}