@import "../../../../App.scss";

.talent-sauce-pricing-main {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 2;
    background: linear-gradient(180deg, var(--primary-color) -3.97%, var(--primary-blue-color) 67.06%);

    .talent-sauce-pricing-overlay-black {
        background-color: black;
        opacity: 0.6;
        width: 100%;
        min-height: 100vh;
        height: max-content;
        position: fixed;
        z-index: 2;

        @include media-breakpoint-down(md) {
            opacity: 0.8;
        }
    }

    .talent-sauce-pricing-product-control {
        display: flex;
        flex-direction: column;
        padding-left: 5%;
        padding-right: 5%;
        z-index: 3;

        .talent-sauce-pricing-product-scroll-control{
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            margin-top: 4px;

            @include media-breakpoint-down(md){
                display: none;
            }

            .talent-sauce-pricing-product-scroll-text{
                color: white;
                font-size: 16px;
                font-family: $fontMedium;
            }

            .talent-sauce-pricing-product-scroll-icon{
                color: white;
                font-size: 16px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 16px;
            }
        }

        .talent-sauce-pricing-text {
            font-size: 18px;
            color: white;
            font-family: $fontMedium;

            @include media-breakpoint-down(lg){
                text-align: center;
            }
        }

        .talent-sauce-pricing-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 24px;
            margin-top: 36px;

            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
            }

            .talent-sauce-pricing-grid-item {
                border-radius: 16px;
                background: #110135;
                backdrop-filter: blur(22px);
                padding: 24px;
                width: 100%;
                display: flex;
                flex-direction: column;
                height: fit-content;
                margin-bottom: 36px;
                @include media-breakpoint-down(lg){
                    min-height: 500px;
                    height: auto;
                }
                @include media-breakpoint-down(md){
                    padding: 16px;
                }

                .talent-sauce-pricing-grid-title {
                    font-size: 18px;
                    color: white;
                    font-family: $fontMedium;
                    margin-top: 24px;

                    @include media-breakpoint-down(lg){
                        text-align: center;
                    }
                }

                .talent-sauce-pricing-grid-content-control {
                    display: grid;
                    grid-template-columns: 40% 60%;
                    margin-top: 16px;
                    @include media-breakpoint-down(lg){
                        grid-template-columns: 100%;
                        height: 100%;
                    }
                    @include media-breakpoint-down(md){
                        grid-template-columns: 100%;
                    }

                    .talent-sauce-pricing-grid-content-right {
                        display: flex;
                        justify-content: center;

                        .talent-sauce-pricing-grid-content-right-video-control {
                            border-radius: 8px;
                            background-color: transparent;
                            position: relative;
                            margin-left: auto;
                            width: 90%;
                            height: auto;
                            padding: 16px;
                            aspect-ratio: 16/9;

                            @include media-breakpoint-down(lg){
                                display: none;
                            }
                            .talent-sauce-pricing-grid-content-right-video-tag{
                                margin-left: 13px;
                                height: 23px;
                                width: auto;
                                object-fit: contain;
                                position: absolute;
                                z-index: 3;
                                margin-top: -25px;
                            }
                            .talent-sauce-pricing-grid-content-right-video {
                                position: absolute;
                                border-radius: 8px;
                                padding: 4px;
                                top: 0;
                                left: 0;
                            }
                        }

                        .talent-sauce-pricing-grid-content-right-img {
                            width: auto;
                            margin: auto;
                            object-fit: contain;
                            height: 220px;
                            @include media-breakpoint-down(lg){
                                display: none;
                            }
                            @include media-breakpoint-down(md){
                                display: none;
                            }
                        }
                    }

                    .talent-sauce-pricing-grid-content-left {
                        display: flex;
                        flex-direction: column;

                        @include media-breakpoint-down(lg){
                            margin-top: auto;
                        }

                        .talent-sauce-pricing-grid-content-left-img {
                            width: auto;
                            margin: auto;
                            object-fit: contain;
                            height: 220px;
                            display: none;

                            @include media-breakpoint-down(lg){
                                display: flex;
                                margin-top: 8px;
                                margin-bottom: 8px;
                                height: 180px;
                            }
                        }

                        .talent-sauce-pricing-grid-content-left-video-tag{
                            margin-left: 24px;
                            height: 20px;
                            width: auto;
                            object-fit: contain;
                            position: absolute;
                            margin-top: -25px;
                            z-index: 3;
                        }
                        .talent-sauce-pricing-grid-content-left-video-control {
                            display: none;
                            border-radius: 8px;
                            background-color: transparent;
                            position: relative;
                            margin-left: auto;
                            position: relative;
                            margin-top: 16px;
                            margin-bottom: 16px;
                            width: 100%;
                            height: auto;
                            padding: 16px;
                            aspect-ratio: 16/9;

                            @include media-breakpoint-down(lg){
                                display: flex;
                            }

                            .talent-sauce-pricing-grid-content-right-video {
                                position: absolute;
                                border-radius: 8px;
                                padding: 4px;
                                top: 0;
                                left: 0;
                            }
                        }

                        .talent-sauce-pricing-grid-content-what-did {
                            font-size: 14px;
                            color: var(--primary-color);
                            font-family: $fontMedium;
                            text-decoration: underline;

                            @include media-breakpoint-down(lg){
                                text-align: center;
                            }
                        }

                        .talent-sauce-pricing-grid-content-logo-control {
                            display: grid;
                            grid-template-columns: 50% 50%;
                            margin-top: 24px;
                            margin-bottom: 24px;
                            @include media-breakpoint-down(lg){
                                display: flex;
                                justify-content: center;
                            }
                          
                            @include media-breakpoint-down(md){
                               display: flex;
                                margin-bottom: 4px;
                                margin-top: 4px;
                                justify-content: center;
                            }

                            .talent-sauce-pricing-grid-content-logo {
                                height: 50px;
                                width: fit-content;
                                object-fit: contain;
                                margin-bottom: 8px;
                                @include media-breakpoint-down(lg){
                                    height: 50px;
                                }
    
                            }
                        }

                        .talent-sauce-pricing-grid-chat-button {
                            border-radius: 24px;
                            border: 1px solid var(--primary-color);
                            color: var(--primary-color);
                            font-size: 14px;
                            font-family: $fontMedium;
                            width: fit-content;
                            height: 46px;
                            background-color: transparent;

                            &:hover{
                                background-color: var(--primary-color);
                                color: white;
                            }

                            @include media-breakpoint-down(lg){
                                width: 100%;
                                background-color: var(--primary-color);
                                color: white;
                                margin-top: auto !important;
                            }
                            @include media-breakpoint-down(md){
                                width: 100%;
                                background-color: var(--primary-color);
                                color: white;
                            }
                        }

                        .talent-sauce-pricing-grid-chat-icon {
                            margin-left: 14px;
                        }
                    }
                }

                .talent-sauce-pricing-grid-tag {
                    border-radius: 4px;
                    background: var(--primary-color);
                    margin-left: 16px;
                    margin-top: -41.5px;
                    height: 35px;
                    width: fit-content;
                    padding-left: 16px;
                    padding-right: 16px;
                    display: flex;

                    @include media-breakpoint-down(md){
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: -25px;
                    }

                    .talent-sauce-pricing-grid-tag-icon {
                        width: 20px;
                        height: 20px;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .talent-sauce-pricing-grid-tag-text {
                        font-size: 16px;
                        font-family: $fontMedium;
                        color: white;
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-left: 16px;
                    }
                }
            }

        }
    }

    .talent-sauce-pricing-cover-img {
        position: absolute;
        background-image: url(../../../../assets/images/techsauce/pricing-landing.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 200vh;
        z-index: 0;
        mix-blend-mode: color-burn;
        opacity: 0.5;
    }

    .talent-sauce-pricing-quotation-button {
        margin-left: auto;
        padding-left: 24px;
        padding-right: 24px;
        border-radius: 24px;
        height: 45px;
        border: none;
        font-size: 16px;
        font-family: $fontMedium;
        background-color: var(--primary-color);
        color: white;

        @include media-breakpoint-down(md) {
            margin-right: auto;
            margin-left: auto;
            margin-top: 16px;
        }
    }

    .talent-sauce-pricing-cover-header-control {

        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;

        @include media-breakpoint-down(lg) {
            min-height: 70vh;

        }

        @include media-breakpoint-down(md) {
            min-height: 700px;
            height: auto;

        }

        .talent-sauce-pricing-title-control {
            display: flex;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }


        .talent-sauce-pricing-title-text {
            font-size: 20px;
            color: var(--text-white-color);
            font-family: $fontMedium;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .talent-sauce-pricing-desc-text {
            font-size: 16px;
            color: var(--text-white-color);
            font-family: $fontRegularLINE;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .talent-sauce-pricing-desc-text-contact {
            color: var(--primary-color);
            font-family: $fontMedium;
            cursor: pointer;
            margin-left: 6px;
            text-decoration: underline;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .talent-sauce-pricing-cover-header-text-control {
            display: flex;
            flex-direction: column;
            z-index: 3;
            padding-top: 5%;
            justify-content: center;
            padding-left: 5%;
            padding-right: 5%;

            @include media-breakpoint-down(lg) {
                margin-left: 5%;
                margin-right: 5%;
            }

            @include media-breakpoint-down(md){
                padding-left: 2%;
                padding-right: 2%;
                margin-left: 2%;
                margin-right: 2%;
            }



        }


        .talent-sauce-pricing-grid-control {
            display: grid;
            margin-top: 36px;
            grid-template-columns: repeat(4, 25%);

            @include media-breakpoint-down(lg) {
                grid-template-columns: repeat(2, 50%);
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(2, 50%);
            }

            @include media-breakpoint-down(sm) {
                grid-template-columns: 100%;
            }

            .talent-sauce-pricing-item-control {
                width: 95%;
                display: flex;
                margin-left: auto;
                margin-right: auto;

                @include media-breakpoint-down(md) {
                    margin-bottom: 16px;
                }

                @include media-breakpoint-down(sm){
                    width: 100%;
                }

                .talent-sauce-pricing-item-highlight {
                    background-color: #C7582170 !important;
                }

                .talent-sauce-pricing-item {
                    cursor: pointer;
                    border-radius: 16px;
                    width: 100%;
                    min-height: 450px;
                    height: auto;
                    background-color: #110135;
                    padding: 16px 12px;
                    margin-top: auto;
                    margin-bottom: auto;
                    display: flex;
                    position: relative;
                    flex-direction: column;


                    @include media-breakpoint-down(sm) {
                        min-height: 290px;
                    }

                    // &:hover {
                    //     background-color:  #C7582170;
                    //     color: white;
                    // }

                    // &:hover .talent-sauce-pricing-package {
                    //     color: white;
                    // }

                    // &:hover .talent-sauce-pricing-baht-sign-standard{
                    //     color: #C3C0D6;
                    // }

                    // &:hover .talent-sauce-pricing-baht-sign {
                    //     color: #C3C0D6;
                    // }

                    // &:hover .talent-sauce-pricing-credits-ontop{
                    //     color: var(--text-white-color);
                    // }

                    // &:hover .talent-sauce-pricing-credits-ontop-gray{
                    //     color: var(--text-white-color);
                    // }

                    // &:hover .talent-sauce-pricing-months{
                    //     color: var(--text-white-color);
                    // }

                    // &:hover .talent-sauce-pricing-button{
                    //     background-color: var(--primary-color);
                    //     color: var(--text-white-color);
                    // }
                }

                .talent-sauce-pricing-early-tag {
                    position: absolute;
                    width: 130px;
                    height: auto;
                    right: 0;
                    margin-left: auto;
                    margin-right: 4%;
                    top: 0;



                    @media (max-width: 1199px) {
                        width: 35%;
                    }

                    @media (min-width: 1200px) {
                        width: 110px;
                    }

                    @include media-breakpoint-down(lg) {
                        width: 35%;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 30%;
                    }
                }


                .talent-sauce-pricing-package {
                    font-size: 26px;
                    font-family: $fontRegularLINE;
                    color: var(--text-primary-soft-color);

                    @media screen and (max-width: 1440px) {
                        font-size: 20px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                    }


                    @include media-breakpoint-down(sm) {
                        font-size: 26px;
                    }
                }

                .talent-sauce-pricing-baht-sign-standard {

                    font-family: $fontRegularLINE;
                    color: #384970;
                    margin-top: auto;
                    margin-bottom: auto;

                    @media screen and (max-width: 1440px) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 22px;

                    }
                }

                .talent-sauce-pricing-baht-sign {
                    font-size: 40px;
                    font-family: $fontRegularLINE;
                    color: #384970;
                    margin-top: auto;
                    margin-bottom: auto;

                    @media screen and (max-width: 1440px) {
                        font-size: 28px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 28px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 35px;

                    }
                }



                .talent-sauce-pricing-credits {
                    margin-top: 16px;
                    font-size: 24px;
                    font-family: $fontRegularLINE;
                    color: var(--text-white-color);


                    @media screen and (max-width: 1440px) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 24px;
                    }
                }

                .talent-sauce-pricing-credits-ontop {
                    font-size: 20px;
                    font-family: $fontRegularLINE;
                    color: var(--primary-color);

                    @media screen and (max-width: 1440px) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                    }
                }

                .talent-sauce-pricing-credits-ontop-gray {
                    color: #C3C0D6;
                }

                .talent-sauce-pricing-credits-ontop-highlight {
                    font-size: 20px;
                    font-family: $fontRegularLINE;
                    color: var(--primary-blue-color);

                    @media screen and (max-width: 1440px) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                    }
                }

                .talent-sauce-pricing-months {
                    margin-top: 24px;
                    font-size: 24px;
                    font-family: $fontRegularLINE;
                    color: #C3C0D6;

                    @media screen and (max-width: 1440px) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 18px;
                    }
                }

                .talent-sauce-pricing-button {
                    font-size: 22px;
                    font-family: $fontMedium;
                    color: var(--primary-color);
                    width: 100%;
                    height: 48px;
                    border: 1px solid var(--primary-color);
                    border-radius: 44px;
                    margin-top: auto;
                    background-color: transparent;

                    .talent-sauce-pricing-icon {
                        margin-left: 6px;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    &:hover {
                        background-color: var(--primary-color);
                        color: var(--text-white-color)
                    }

                    @media screen and (max-width: 1440px) {
                        font-size: 16px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 16px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                        margin-top: 36px;
                    }
                }

                .talent-suace-pricing-white-label {
                    color: var(--text-white-color) !important;
                }

                .talent-sauce-pricing-button-highlight {
                    font-size: 22px;
                    font-family: $fontMedium;
                    background-color: var(--primary-color);
                    box-shadow: 0px 8px 12px 0px rgba(51, 51, 51, 0.12);
                    color: var(--text-white-color);
                    width: 100%;
                    height: 48px;
                    border: 1px solid var(--primary-color);
                    border-radius: 44px;
                    margin-top: auto;

                    .talent-sauce-pricing-icon {
                        margin-left: 6px;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    @media screen and (max-width: 1440px) {
                        font-size: 16px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 16px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                        text-align: center;
                    }
                }

                .talent-sauce-pricing-baht-value {
                    font-size: 45px;
                    font-family: $fontMedium;
                    color: var(--text-white-color);
                    margin-top: auto;
                    margin-bottom: auto;

                    @media screen and (max-width: 1440px) {
                        font-size: 35px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 35px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 33px;
                    }
                }

                .talent-sauce-pricing-early-bird {
                    font-size: 18px;
                    color: var(--primary-color);
                    font-family: $fontMedium;
                }

                .talent-sauce-pricing-baht-value-standard {
                    text-decoration: line-through;
                    text-decoration-color: #C3C0D6;
                    /* Set your desired color */
                    font-size: 28px;
                    font-family: $fontRegularLINE;
                    color: #C3C0D6;
                    margin-top: auto;
                    margin-bottom: auto;

                    @media screen and (max-width: 1440px) {
                        font-size: 26px;
                    }

                    @include media-breakpoint-down(xl) {
                        font-size: 26px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 22px;
                    }
                }


                .talent-sauce-pricing-package-soft {
                    font-size: 18px;
                    font-family: $fontMedium;
                    color: var(--text-primary-soft-color);
                }

                .talent-sauce-pricing-highlight-color-white {
                    color: var(--text-white-color);
                }

                .talent-sauce-pricing-highlight-color-button {
                    color: var(--text-white-color);
                    background-color: var(--primary-color);
                }

                .talent-sauce-pricing-highlight-color {
                    color: #C3C0D6;
                }
            }
        }


    }

    .talent-sauce-pricing-section-2-control {
        z-index: 3;

        padding-top: 18%;
        padding-left: 8%;
        padding-right: 8%;
        padding-bottom: 8%;
        min-height: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-image: url(../../../../assets/images/techsauce/wave-pricing.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;

        @media screen and (max-height: 700px) {}

        @include media-breakpoint-down(xl) {}

        @include media-breakpoint-down(lg) {
            padding-top: 30%;
        }

        @include media-breakpoint-down(md) {
            margin-top: 4%;
            padding-top: 50%;
        }


        .talent-sauce-pricing-section-2-content-control {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

        }

        .talent-sauce-pricing-section-2-contact {
            margin-top: 48px;
            display: flex;

            @include media-breakpoint-down(md) {
                text-align: center;
            }

            .talent-sauce-pricing-section-2-orange {
                cursor: pointer;
                color: var(--text-primary-soft-color);

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .talent-sauce-pricing-section-2-title {
            font-size: 20px;
            color: var(--text-white-color);
            font-family: $fontMedium;
        }

        .talent-sauce-pricing-section-2-desc {
            font-size: 16px;
            color: var(--text-white-color);
            font-family: $fontRegularLINE;
        }

        .talent-sauce-pricing-section-content-item {

            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 24px;


            .talent-sauce-pricing-section-content-item-divider {
                margin-top: 6px;
                border-color: var(--text-white-color);
            }

            .talent-sauce-pricing-section-content-item-header-control {
                display: grid;
                grid-template-columns: 90% 10%;
                padding-top: 14px;
                padding-bottom: 14px;
                cursor: pointer;


                .talent-sauce-pricing-section-content-item-header {
                    font-size: 18px;
                    color: var(--text-white-color);
                    font-family: $fontRegularLINE;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .talent-sauce-pricing-section-content-item-icon {
                    font-size: 16px;
                    color: var(--text-white-color);
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .talent-sauce-pricing-section-content-item-desc-control {

                padding: 16px;

                .talent-sauce-pricing-section-content-item-desc {
                    font-size: 14px;
                    color: var(--text-white-color);
                    font-family: $fontRegular;
                }


            }
        }

    }
}