@import "../../../App.scss";

.footer-landing{
    background-color: var(--primary-blue-color);
    min-height: 100px;
    width: 100%;
    margin-top: 48px;
    padding-top: 36px;
    padding-bottom: 36px;

    .footer-landing-control{
        display: grid;
        grid-template-columns: 30% 70%;

        .footer-landing-logo-control{
            display: flex;
            flex-direction: column;

            .footer-landing-logo-img{
                width: 200px;
                height: auto;
                object-fit: contain;
            }
        }

    }
}