@import "../../App.scss";


.not-found-main{

    width: 100vw;
    height: 100vh;
    display: flex;

    .not-found-logo{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 26px;
        margin-left: auto;
        margin-right: auto;
        opacity: 0.5;
        width: auto;
        height: 60px;
        object-fit: contain;
    }

    .not-found-control{
        margin: auto;
        width: 50%;
        border-radius: 8px;
        height: 50%;
        display: flex;
        flex-direction: column;
        padding: 36px;
        box-shadow: 0px 0px 5px 5px var(--primary-color);

        @include media-breakpoint-down(md){
            width: 90%;
        }

        .not-found-item-control{
            margin: auto;
            display: flex;
            flex-direction: column;

            .not-found-item-icon{
                width: 70px;
                height: 70px;
                margin-left: auto;
                margin-right: auto;
                color: var(--error-color);
            }
            .not-found-item-text{
                font-size: 20px;
                margin-top: 36px;
                text-align: center;
                font-family: $fontMedium;
                margin-left: auto;
                margin-right: auto;
                color: var(--text-color);
            }

            .not-found-item-home{
                font-size: 20px;
                text-align: center;
                font-family: $fontMedium;
                margin-top: 36px;
                cursor: pointer;
                text-decoration: underline;
                margin-left: auto;
                margin-right: auto;
                color: var(--link-color);
            }
        }
    }
}