@import "../../App.scss";

.account-main{
    display: flex;
    width: 100%;
    
    .account-main-loading-control{
        width: 100%;
        min-height: 400px;
        display: flex;

        .account-main-loading-item{
            margin: auto;
            display: flex;
            flex-direction: column;

            .account-main-loading-item-progress{
                width: 40px;
                height: 40px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .account-main-control{
        display: flex;
        flex-direction: column;
        margin: auto;
        padding-top: 36px;
        width: 80%;

       
        .account-main-grid-form{
            width: 100%;
            display: grid;
            grid-template-columns: 50% 50%;

            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
            }

            .account-main-item-control{
                display: flex;
                flex-direction: column;
                width: 90%;
                margin-top: 24px;

                @include media-breakpoint-down(md){
                    width: 100%;
                }
            }

            .account-main-item-label{
                font-size: 13px;
                font-family: $fontRegular;
                color: var(--text-gray-color);
            }

            .account-main-item-input-form{
                height: 45px;
                width: 100%;
                font-size: 14px;
                font-family: $fontMedium;
                border-color: var(--border-input);

                &:focus{
                    box-shadow: var(--primary-color);
                }
            }

           
        }

        .account-main-text-control{
            margin-bottom: 14px;
            display: flex;
        }

        .account-main-text-title{
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--text-black-color);
        }
        .account-main-text-desc{
            font-size: 14px;
            font-family: $fontRegular;
            color: var(--text-black-color);
        }
        .account-main-text-link{
            margin-left: 14px;
            cursor: pointer;
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--link-color);
            text-decoration: underline;
        }
        .account-main-button-submit{
            margin: 24px auto;
            width: 40%;
            height: 45px;
            border-radius: 8px;
            background-color: var(--button-primary);
            border: none;
            box-shadow: 0px 3px 5px var(--border-input);
            color: var(--text-black-color);
            font-size: 15px;
            color: var(--text-white-color);
            font-family: $fontMedium;
        }


    }
    
}