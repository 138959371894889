@import "../../../../App.scss";


.audience-create-modal{

    .modal-dialog {
        max-width: 80% !important;
        width: 80% !important;
    }
}
.create-audience-loading-control{
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    .create-audience-loading-progress-control{
        margin: auto;
        display: flex;
        flex-direction: column;
    }
    .create-audience-loading-progress{
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }

    .create-audience-loading-text{
        font-size: 15px;
        color: var(--text-black-color);
        font-family: $fontRegular;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
    }
}
.create-audience{
    margin-top: 16px;
    display: grid;
    grid-template-columns: 50% 50%;
    border-radius: 8px;


    
    .MuiOutlinedInput-input, .MuiFormControlLabel-label{
        font-family: $fontRegular;
    }
    .create-audience-headline{
        font-size: 16px;
        font-family: $fontMedium;
    }

    .create-audience-name-control{
        margin-bottom: 16px;
        width: 90%;
        display: flex;
        flex-direction: column;
        font-family: $fontRegular;
        font-size: 14px;
    }

    .create-audience-form-control{
        width: 90%;
        margin-top: 12px;
        margin-bottom: 12px;
        border: 1px solid var(--border-input);
        border-radius: 12px;
        padding: 12px 18px;
        display: flex;
        flex-direction: column;
    }

    .create-audience-form-control-flex{
        display: flex;
        flex-direction: row;
    }
    .create-audience-tag-control{
        display: flex;
        margin-top: 12px;
    }

    .crete-audience-tag-value{
        font-size: 12px;
        font-family: $fontRegular;
        color: var(--text-black-color);
        background-color: var(--link-color);
        padding: 2px 12px;
        border-radius: 26px;
        margin-right: 4px;
    }

    .create-audience-radio-flex-row{
        display: flex;
        flex-direction: row;
    }

    .create-audience-form-checkbox{
    }
    .create-audience-form-margin{
        margin-top: 16px;
    }

    .create-audience-title-form{
        font-size: 14px;
        font-family: $fontMedium;
    }

    .create-audience-line-oa{
        display: flex;
        flex-direction: column;
    }
    .create-audience-required-error{
        font-size: 13px;
        margin-top: 4px;
        color: red;
        font-family: $fontRegular;
    }

    .create-audience-button-submit{
        font-size: 15px;
        color: white;
        background-color: var(--button-primary);
        border-radius: 8px;
        min-width: 200px;
        width: 30%;
        margin-right: 16px;
        height: 41px;
        padding: auto 14px;
        margin-top: 12px;

        &:hover{
            background-color: var(--button-primary);
        }
    }
    .create-audience-button-delete{
        font-size: 15px;
        color: var(--text-black-color);
        background-color: var(--border-input);
        border-radius: 8px;
        min-width: 200px;
        width: 30%;
        margin-right: 16px;
        height: 41px;
        padding: auto 14px;
        margin-top: 12px;

        &:hover{
            background-color: var(--border-input);
        }
    }
}