@import "../../../../App.scss";

.share-job {
max-width: 60vw !important;

@include media-breakpoint-down(md){
    max-width: 90vw !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

    .share-img {
        width: 1200px;
        height: 1200px;

        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 10px var(--border-input);
        margin-left: auto;
        margin-right: auto;
        margin-top: 36px;
        zoom: 0.5;

        @include media-breakpoint-down(md) {
            zoom: 0.2;
            margin-top: 48px;
        }

        .share-img-control {
            position: relative;
            margin: auto;
            width: 100%;

            height: 100%;
            background-color: #fafafa;
            display: flex;
            padding: 24px;
            flex-direction: column;

            .share-img-job-title {
                font-size: 3.2rem;
                font-family: $fontMedium;
                color: var(--text-black-color);
            }

            .share-img-job-title-small {
                font-size: 2.4rem;
                font-family: $fontMedium;
                color: var(--text-black-color);
            }

            .share-img-company {
                font-size: 2.4rem;
                font-family: $fontRegular;
                color: var(--text-black-color);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .share-img-company-small {
                font-size: 2.4rem;
                font-family: $fontMedium;
                color: var(--text-black-color);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .share-img-exp {
                margin-top: 24px;
                font-size: 2.4rem;
                font-family: $fontRegular;
                color: var(--text-black-color);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .share-img-exp-title {
                margin-top: 24px;
                font-size: 3.6rem;
                font-family: $fontMedium;
                color: var(--text-black-color);
                margin-left: 14px;
                margin-right: 14px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .share-img-exp-title-small {
                margin-top: 24px;
                font-size: 2.4rem;
                font-family: $fontRegular;
                color: var(--text-black-color);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .share-img-salary-title {
                margin-top: 15%;
                font-size: 3.6rem;
                font-family: $fontRegular;
                color: #6C7686;
                margin-right: 6px;
            }

            .share-img-salary {
                margin-top: 5%;
                font-size: 4.8rem;
                font-family: $fontMedium;
                margin-left: 4px;
                color: var(--text-black-color);
            }

            .share-img-salary-small {
                font-size: 2.4rem;
                font-family: $fontMedium;
                margin-left: 4px;
                color: var(--text-black-color);
            }

            .share-img-location-control {
                display: flex;
                margin-top: 5%;

                .share-img-location-pin {
                    width: auto;
                    height: 60px;
                    object-fit: contain;
                }

                .share-img-location-pin-text {
                    font-size: 3.6rem;
                    margin-left: 4px;
                    font-family: $fontRegular;
                    color: #555E6D;
                    margin-top: auto;
                    margin-bottom: auto;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .share-img-location-pin-text-small {
                    font-size: 2.4rem;
                    width: 150px;
                    margin-left: 4px;
                    font-family: $fontRegular;
                    color: #555E6D;
                    margin-top: auto;
                    width: 100%;
                    margin-bottom: auto;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            .share-img-qr-control {
                margin-top: auto;
                width: 350px;
                min-height: 350px;
                display: flex;
                flex-direction: column;

            }

            .share-img-apply {
                font-size: 36px;
                color: #555E6D;
                font-family: $fontMedium;
                margin-left: auto;
                margin-right: auto;
                margin-top: 12px;

            }

            .share-img-qr {
                width: 350px;
                height: 350px;
                margin-left: auto;
                margin-right: auto;
                object-fit: contain;
                margin-top: 12px;
            }

            .share-img-job-post-img-control {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin-top: auto;
                margin-left: auto;
                display: flex;
                flex-direction: column;

                .share-img-job-post-img {
                    width: 400px;
                    height: auto;
                    margin-top: auto;
                    margin-left: auto;
                    object-fit: contain;
                }


                .share-img-job-post-img-text {
                    font-size: 1.4rem;
                    margin-bottom: 24px;
                    margin-top: 8px;
                    color: var(--text-black-color);
                    font-family: $fontMedium;
                }
            }



        }




    }


    .share-img-button-download-control {
        display: flex;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    .share-img-button-download {
        width: 300px;
        height: 46px;
        border-radius: 6px;
        border: none;
        margin-left: 15px;
        margin-right: 15px;
        background-color: var(--button-primary);
        color: var(--text-button-color);
        border: 2px solid var(--button-primary);

        &:hover {
            background-color: var(--button-primary);
            color: white;
        }

        @include media-breakpoint-down(md) {
            height: 41px;
            margin-bottom: 16px;
            width: 200px;
        }
    }

    .share-img-back {
        font-size: 22px;
        text-decoration: underline;
        cursor: pointer;
        color: var(--primary-color);
        font-family: $fontRegular;
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;
    }
}