@import "../../App.scss";

.login-control{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;


    .login-main-container{
        width: 50%;
        display: flex;
        flex-direction: column;
        margin: auto;
        @include media-breakpoint-down(md){
            width: 100%;
        }

        .login-main-logo-img{
            width: 250px;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .login-main-logo-title-text{
            margin-top: 16px;
            font-size: 18px;
            color: var(--text-black-color);
            font-family: $fontMedium;
            text-align: center;
        }

        .login-main-button-control{
            margin-top: 15%;
            display: flex;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            flex-direction: column;
            @include media-breakpoint-down(xl){
                width: 90%;
            }
            @include media-breakpoint-down(lg){
                width: 90%;
            }
            @include media-breakpoint-down(md){
                width: 100%;
            }

            .login-main-button-register{
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                height: 61px;
                background-color: var(--button-secondary);
                border-radius: 8px;
                color: var(--text-button-secondary-color);
                font-size: 17px;
                font-family: $fontMedium;
                border: 2px solid var(--button-primary);
                box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;

                &:hover{
                    background-color: var(--button-primary);
                    color: var(--text-button-color)
                }
            }

            .login-main-button-login{
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 48px;
                height: 61px;
                background-color: var(--button-primary);
                border-radius: 8px;
                color: var(--text-button-color);
                font-size: 17px;
                font-family: $fontMedium;
                border: 2px solid var(--button-primary);
                box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;


                &:hover{
                    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px var(--button-primary), 0 1px 5px var(--button-primary);
                }
            }

           
        }
        
    }
    .login-main-footer-control{
        margin-top: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;

        .login-main-footer-img-logo{
            width: 150px;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .login-main-footer-text{
            font-size: 15px;
            color: var(--text-gray-color);
            font-family: $fontRegular;
            margin-top: 12px;
            margin-left: auto;
            margin-right: auto;
        }
    }

}