@import "../App.scss";


.topup-modal {

    display: flex;
    flex-direction: column;

    .topup-control{
        margin: auto;
        display: flex;
        flex-direction: column;

        .topup-link{
            color: var(--primary-color);
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
        .topup-img{
            width: 90px;
            height: 90px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 24px;
        }

        .topup-buy-button{
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            background-color: var(--primary-color);
            border-radius: 24px;
            border:none;
            font-size: 14px;
            font-family: $fontMedium;
            margin-bottom: 16px;

            &:active{
                background-color: var(--primary-color) !important;
            }
        }

        .topup-text{
            font-size: 14px;
            color: var(--text-black-color);
            font-family: $fontMedium;
            text-align: center;
        }

        .topup-button{
            min-width: 150px;
            height: 45px;
            background-color: var(--button-primary);
            color: var(--text-button-color);
            font-family: $fontMedium;
            font-size: 18px;
            border-radius: 8px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
        }
    }
}