@import "../../../App.scss";


.header-landing {
    background-color: var(--bg-color);

    .header-button{
        background-color: var(--button-primary) !important;
        border: none;
    }


    .header-grid-control {
        width: 100%;
        margin-left: auto;
        padding-top: 8px;
        padding-bottom: 8px;
        ;
        margin-right: auto;
        padding-left: 0px;
        padding-right: 0px;
        display: grid !important;
        grid-template-columns: 30% 40% 30% !important;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 50% 50% !important;
            padding-left: 2%;
            padding-right: 2%;
        }

        .header-logo-control {
            display: flex;

            .header-logo-img {
                cursor: pointer;
                width: 150px;
                height: auto;
                object-fit: contain;
                margin-top: auto;
                margin-bottom: auto;

                @include media-breakpoint-down(md) {
                    height: 41px;
                    object-fit: contain;
                    width: auto;
                }
            }

        }

        .header-menu-authen-control {
            display: flex;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(lg) {
                display: none;
            }



        }


        .header-menu-center-control {

            display: flex;
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(lg) {
                display: none;
            }

        }

        .header-menu-a {
            margin-left: 12px;
            margin-right: 12px;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 16px;
            color: var(--text-black-color);
            font-family: $fontRegular;

            &:hover {
                font-family: $fontMedium;
                color: var(--text-blue-color);
                text-decoration: underline;
            }
        }

        .header-menu-a-active {
            margin-left: 12px;
            margin-right: 12px;
            font-size: 16px;
            color: var(--text-blue-color);
            font-family: $fontMedium;
            text-decoration: underline;

            &:hover {
                color: var(--text-blue-color);
                text-decoration: underline;
            }
        }




        .header-menu-nav-mobile-control {
            display: none;

            @include media-breakpoint-down(lg) {
                display: block;
                margin-left: auto;
            }
        }


    }



    .header-nav-link-control {
        margin-left: auto;
    }
}

.header-menu-authen-login-control {
    cursor: pointer;
    display: flex;
    border: 2px solid black;
    background-color: var(--primary-blue-color);
    padding: 6px 18px;
    border-radius: 8px;

    @include media-breakpoint-down(md) {
        background-color: var(--primary-color);
        color: var(--text-white-color);
        justify-content: center;
        border: none;
    }

    .header-menu-authen-login-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: auto;
    }

    .header-menu-authen-login-text {
        font-size: 18px;
        color: var(--text-black-color);
        margin-left: 12px;
        margin-top: auto;
        margin-bottom: auto;
        font-family: $fontMedium;

        @include media-breakpoint-down(md) {
            color: var(--text-white-color);
        }
    }

    
}
.header-authen-login-mobile-control{
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    background-color: white;
    border-radius: 8px;
    padding: 12px;

    .header-authen-login-mobile-profile{
        display: flex;

        .header-authen-login-mobile-profile-img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: contain;
            margin-top: auto;
            margin-bottom: auto;
        }
        .header-authen-login-mobile-profile-text {
            font-size: 18px;
            color: var(--text-black-color);
            margin-left: 12px;
            margin-top: auto;
            margin-bottom: auto;
            font-family: $fontMedium;
    
            @include media-breakpoint-down(md) {
                color: var(--text-black-color);
            }
        }
    }

    .header-authen-login-mobile-divider{
        width: 100%;
        height: 2px;
        background-color: var(--border-line);
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .header-authen-login-mobile-credit-text {
        font-size: 18px;
        color: var(--text-black-color);
        margin-top: auto;
        margin-bottom: auto;
        font-family: $fontMedium;

        @include media-breakpoint-down(md) {
            color: var(--text-black-color);
        }
    }
    .header-authen-login-mobile-credit-control{
        display: flex;
        margin-left: auto;
    }

    .header-authen-login-mobile-credit-img{
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
    }

    .header-authen-login-mobile-credit-text-total{
        font-size: 18px;
        color: var(--text-black-color);
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 24px;
        font-family: $fontMedium;

        @include media-breakpoint-down(md) {
            color: var(--text-black-color);
        }
    }

    .header-authen-login-mobile-sub-text{
        font-size: 14px;
        color: var(--text-gray-color);
        margin-top: 12px;
        margin-bottom: 12px;
        font-family: $fontMedium;

        @include media-breakpoint-down(md) {
            color: var(--text-black-color);
        }
    }
    
    
}


.navbar-toggler {
    background-color: var(--secondary-color) !important;

    .navbar-toggler-icon {
        color: red !important;
        font-family: $fontMedium !important;
        font-weight: bold !important;
    }
}



.header-navbar-side {

    display: none;

    @include media-breakpoint-down(lg) {
        padding: 8px 16px;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: absolute;
        background-color: white;
    }

    .header-navbar-divider {
        width: 100%;
        height: 3px;
        border-radius: 12px;
        margin-top: 12px;
        margin-bottom: 12px;
        background-color: var(--border-line);
    }

    .header-navbar-a {
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        color: var(--text-black-color);
        font-family: $fontMedium;

        &:hover {
            font-family: $fontMedium;
            color: var(--text-blue-color);
            text-decoration: underline;
        }
    }

    .header-navbar-active {
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        color: var(--primary-color);
        font-family: $fontMedium;
        text-decoration: underline;

        &:hover {
            font-family: $fontMedium;
            color: var(--text-blue-color);

        }
    }

    .header-navbar-side-logo-control {

        display: flex;
        position: relative;

        .header-navbar-side-logo-img {
            width: 150px;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .header-navbar-side-close-img {
            width: 20px;
            position: absolute;
            cursor: pointer;
            right: 0;
            margin-top: auto;
            top: 0;
            bottom: 0;
            margin-bottom: auto;
            height: auto;
            object-fit: contain;
            margin-left: auto;
        }
    }

}