@import "../../../../App.scss";

.campaign-create-modal{

    .modal-dialog {
        max-width: 60% !important;
        width: 60% !important;
    }
}

.create-campaign-control{
    display: flex;
    flex-direction: column;

    .MuiSelect-select {
        font-family: $fontRegular;
    }

    .MuiTypography-root{
    font-family: $fontRegular;
    }

    .MuiOutlinedInput-input{
        padding: 6px 14px;
    }
    .create-campaign-label{
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-black-color);
        margin-bottom: 4px;
        margin-top: 16px;
    }

    .create-campaign-approx-volume-control{
        margin-bottom: 16px;
        display: flex;

        .create-campaign-approx-volum-big{
            font-size: 18px;
            font-family: $fontMedium;
            color: var(--primary-color);
            margin-left: 8px;
        }
    }
    .create-campaign-label-example{
        font-size: 12px;
        margin-top: 16px;
        font-family: $fontRegular;
        color: var(--text-gray-color);
        margin-bottom: 4px;
    }
    .create-campaign-name-control{
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        font-family: $fontRegular;
        font-size: 14px;
    }
    .create-campaign-form-control{
        width: 90%;
        margin-top: 12px;
        margin-bottom: 12px;
        border: 1px solid var(--border-input);
        border-radius: 12px;
        padding: 12px 18px;
        display: flex;
        flex-direction: column;
    }
    .create-campaign-form-control-noborder{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }

    .create-campaign-preview-img{
        width: 50%;
        height: auto;
        object-fit: contain;
    }
    .create-variant-control-grid{
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .create-variant-control{
        border: 1px solid var(--border-input);
        border-radius: 5px;
        padding: 16px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
    }
    .create-campaign-form-margin{
    }
    .create-campaign-tag-control{
        display: flex;
        margin-top: 12px;
    }
    .create-campaign-label-last-flex{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 4px;
        font-size: 16px;
        font-family: $fontMedium;
    }

    .crete-campaign-tag-value{
        cursor: pointer;
        font-size: 14px;
        font-family: $fontRegular;
        color: var(--text-white-color);
        background-color: var(--link-color);
        padding: 6px 12px;
        border-radius: 26px;
        margin-right: 4px;
    }
    .create-campaign-button-submit{
        font-size: 15px;
        color: var(--text-button-color);
        background-color: var(--button-primary);
        border-radius: 8px;
        min-width: 200px;
        width: 30%;
        border: none;
        margin-right: 16px;
        height: 41px;
        padding: auto 14px;
        margin-top: 12px;

        &:hover{
            background-color: var(--button-primary);
        }
    }
    .create-campaign-button-delete{
        font-size: 15px;
        color: var(--text-black-color);
        background-color: var(--border-input);
        border-radius: 8px;
        min-width: 200px;
        width: 30%;
        border: none;
        margin-right: 16px;
        height: 41px;
        padding: auto 14px;
        margin-top: 12px;

        &:hover{
            background-color: var(--border-input);
        }
    }
    .create-audience-required-error{
        font-size: 12px;
        color: $red;
    }
}

.create-campaign-loading-control{
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    .create-campaign-loading-progress-control{
        margin: auto;
        display: flex;
        flex-direction: column;
    }
    .create-campaign-loading-progress{
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }

    .create-campaign-loading-text{
        font-size: 15px;
        color: var(--text-black-color);
        font-family: $fontRegular;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
    }
}