@import "../App.scss";


.error-modal{
    display: flex;
    flex-direction: column;
    min-height: 400px;
    background-color: #00000060;


    .error-modal-control{
        margin: auto;
        display: flex;
        flex-direction: column;

        .error-modal-img{
            width: 60px;
            height: auto;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .error-modal-text{
            margin-top: 24px;
            font-size: 16px;
            font-family: $fontMedium;
            color: var(--text-black-color);
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .error-modal-button{
            width: 50%;
            margin-top: 24px;
            margin-left: auto;
            margin-right: auto;
            height: 41px;
            color: var(--text-button-color);
            font-family: $fontMedium;
            background-color: var(--button-primary);
            border-radius: 26px;
            border:none;
        }
    }   
}