@import "../../../../App.scss";


.articles-main{
    width: 100vw;
    background-color: black;
    height: auto;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    z-index: 3;
    flex-direction: column;

    .articles-icon{
        width: 50px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;

        @include media-breakpoint-down(sm){
            margin-bottom: 16px;
        }
    }

    .articles-title{
        font-size: 36px;
        color: white;
        font-family: $fontMedium;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(md){
            font-size: 26px;
        }

        @include media-breakpoint-down(sm){
            font-size: 22px;
        }
    }

    .articles-grid-control{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 24px;
        column-gap: 12px;
        width: 100%;
        @include media-breakpoint-down(lg){
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-down(md){
            grid-template-columns: 100%;
        }

        .articles-grid-item{
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: white;

            @include media-breakpoint-down(lg){
                margin-bottom: 16px;
            }

            .articles-grid-img{
                width: 100%;
                height: 200px;
                object-fit: cover;
            }

            .articles-grid-text-control{
                padding: 16px;
                display: flex;
                flex-direction: column;
            }

            .articles-grid-desc{
                font-size: 12px;
                font-family: $fontRegularLINE;
                color: black;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                max-height: 3em; /* adjust based on line-height and font-size */

                @include media-breakpoint-down(md){
                    font-size: 11px;
                }
            }

            .articles-grid-title{
                font-size: 15px;
                font-family: $fontMedium;
                color: black;

                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
            }
        }
    }

    .articles-all-control{
        display: flex;
        margin-top: 36px;
        margin-left: auto;
        margin-right: auto;
        color: var(--primary-color);
        cursor: pointer;

        .articles-all-text{
            font-size: 20px;
            font-family: $fontMedium;
        }

        .articles-all-icon{
            font-size: 20px;
            margin-top: auto;
            margin-left: 16px;
            margin-bottom: auto;
        }
    }
}