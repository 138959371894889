@import "../../../App.scss";


.crm-control{
    display: flex;
    flex-direction: column;
    width: 100%;

    .Mui-selected{
        color: var(--primary-color) !important;
        font-family: $fontMedium;
    }

    .crm-container-desc{
        width: 100%;
        display: flex;
    }

    .crm-tab-custom{
        color: var(--primary-color);
    }
}