@import "../../../../App.scss";


.post-job{
    padding-top: 16px;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #DEEAFF;
    .post-job-description{
        margin-right: auto;
        text-align: left;
        font-family: $fontRegular;
        font-size: 12px;
        color: var(--text-gray-color);

    }
    .post-job-consent{
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-family: $fontMedium;
        font-size: 14px;
        margin-bottom: 14px;
        color: var(--text-gray-color);

        .post-job-consent-a{
            color: var(--primary-color);
            cursor: pointer;
            font-size: 10px;
        }
    }
    .post-job-control{
        margin: auto;
        width: 40%;
        min-height: 400px;
        border-radius: 16px;
        display: flex;
        background-color: var(--primary-color);
        flex-direction: column;

        @include media-breakpoint-down(lg){
            width: 60%;
        }

        @include media-breakpoint-down(md){
            width: 90%;
        }

        .post-job-apply-control{
            display: flex;
            width: 100%;
            padding: 4px 24px;
            height: 100px;

            .post-job-apply-text-control{
                display: flex;
                flex-direction: column;
                padding-top: 16px;

                .post-job-apply-title{
                    font-size: 16px;
                    color: white;
                    margin-top: auto;
                    margin-bottom: auto;
                    font-family: $fontMedium;
                    
                    @include media-breakpoint-down(md){
                        font-size: 12px;
                    }
                }
                
            }
            .post-job-apply-img{
                margin-top: -20px;
                margin-left: auto;
                width: 190px;
                height: auto;
                object-fit: contain;
                @include media-breakpoint-down(md){
                    width: 100px;
                }
            }
          
        }

       

        .post-job-success-control{
            border-radius: 16px;
            background-color: white;
            min-height: 300px;
            width: 100%;
            padding: 24px 20%;
            display: flex;

            .post-job-success-text-control{
                display: flex;
                flex-direction: column;
                margin: auto;

                .post-job-success-img{
                    width: 250px;
                    height: auto;
                    object-fit: contain;
                    margin-left: auto;
                    margin-right: auto;
                }

                .post-job-success-text{
                    font-size: 17px;
                    color: var(--text-black-color);
                    font-family: $fontMedium;
                    margin-top: 24px;
                    margin-left: auto;
                    text-align: center;
                    margin-right: auto;
                }
            }
        }

        .post-job-input-control{
            border-radius: 16px;
            background-color: white;
            height: 100%;
            width: 100%;
            padding: 24px 20%;

            @include media-breakpoint-down(lg){
                padding: 24px 10%;
            }

            .post-job-description{
                font-size: 11px;
                color: var(--text-gray-color);
                font-family: $fontRegular;
                margin-right: auto;
                margin-left: 0;
                text-align: left;
            }

            .post-job-form-control{
                display: flex;
                flex-direction: column;
                width: 100%;

                .post-job-form-item-control{
                    margin-bottom: 16px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }
                .post-job-form-item-resume-control{
                    margin-bottom: 16px;
                    display: flex;
                    width: 100%;

                    @include media-breakpoint-down(lg){
                       flex-direction: column;
                    }
                }
                .post-job-form-title{
                    font-size: 16px;
                    color: var(--text-black-color);
                    font-family: $fontMedium;
                    margin-top: auto;
                    margin-bottom: auto;
                }
                .post-job-form-desc{
                    font-size: 10px;
                    color: var(--text-gray-color);
                    font-family: $fontRegular;
                    
                }
                .post-job-form-error{
                    font-size: 13px;
                    color: red;
                    margin-top: 4px;
                    font-family: $fontMedium;
                    margin-bottom: auto;
                }

                .post-job-resume-button-full{
                    width: 100% !important;
                    padding: 8px !important;
                    font-size: 14px !important;
                    margin-top: 5px !important;
                }

                .post-job-resume-title-control{
                    display: flex;
                    flex-direction: column;
                }

                .post-job-resume-button{
                    width: 150px;
                    height: 46px;
                    margin-left: auto;
                    margin-top: auto;
                    margin-bottom: auto;
                    border-radius: 8px;
                    background-color: var(--button-primary);
                    border: 2px solid var(--primary-color);
                    color: var(--text-button-color);
                    @include media-breakpoint-down(lg){
                        margin-left: 0;
                        margin-right: auto;
                        margin-top: 12px;
                        height: 36px;
                    }
                }
            }

            
            .post-job-submit-button{
                width: 100%;
                height: 51px;
                background-color: var(--button-primary);
                border-radius: 8px;
                border:none;
                color: var(--text-button-color);
                font-size: 16px;
                font-family: $fontMedium;
                margin-top: 26px;
            }
        }
    }

    .post-job-footer{
        width: 200px;
        height: 80px;
        cursor: pointer;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
    }
}