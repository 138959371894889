@import "../App.scss";


.result-modal {

    min-width: 50vw;
    min-height: 100vh;
    display: flex;
    background-color: transparent;

    @include media-breakpoint-down(md){
        margin-left: auto;
        margin-right: auto;
    }

    .modal{
        display: flex !important;
    }
    .modal-content{
        background-color: transparent !important;
        border: none !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    @include media-breakpoint-down(xl){
        width: 80vw;
        max-width: 80vw;
    }

    @include media-breakpoint-down(md){
        width: 90vw;
        max-width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }

    .result-modal-close-control{
        display: flex;
        margin-bottom: 24px;

        .result-modal-close-text{
            border-radius: 26px;
            padding: 4px 24px;
            background-color: var(--primary-color);
            cursor: pointer;
            text-decoration: underline;
            font-size: 15px;
            font-family: $fontRegular;
            margin-left: auto;
            margin-right: auto;
            color: var(--text-white-color);
        }
    }

}