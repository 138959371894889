@import "../../../App.scss";



.custom-resume-main{

    width: 100vw;
    min-height: 100vh;
    display: flex;



    .custom-resume-a4 {
        width: 210mm;
        min-height: 297mm;
        margin: auto auto;
        padding: 5mm 8mm;
        box-shadow: 0 0 5mm rgba(0, 0, 0, 0.1);
        background: white;
        page-break-after: always;
        display: flex;
        flex-direction: column;

        .company-logo-on-bg{
            position: absolute;
            width: 300px;
            height: auto;
            opacity: 0.3;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            object-fit: contain;
        }
    }
}