@import "../../../../../App.scss";


.audience-list-control{
    display: flex;
    flex-direction: column;
    width: 100%;

    .MuiTableCell-root {
        font-family: $fontMedium !important;
        color: var(--text-black-color);
    }
    .audience-list-button-view {
        width: 50%;
        height: 45px;
        border-radius: 8px;
        background-color: var(--button-primary);
        color: var(--text-button-color);
        font-size: 12px;
        margin-bottom: 3px;
        font-family: $fontMedium;

        &:hover{
            background-color: var(--button-primary);
        }
    }

    .audience-list-create-control{
        display: flex;
        margin-left: auto;
    }
    .audience-list-button-create{
        width: 300px;
        height: 41px;
        border-radius: 8px;
        background-color: var(--button-primary);
        color: var(--text-button-color);
        font-family: $fontMedium;
        font-size: 14px;
        margin-left: 14px;
        margin-bottom: 16px;
        &:hover{
            background-color: var(--button-primary);
        }
    }

    .audience-list-loading-control{
        height: 500px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
        .audience-list-loading-progress-control{
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .audience-list-loading-progress{
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }
    
        .audience-list-loading-text{
            font-size: 15px;
            color: var(--text-black-color);
            font-family: $fontRegular;
            margin-top: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}