@import "../App.scss";

.credit-usage-modal{
    .modal-dialog {
        max-width: 80% !important;
        width: 80% !important;
        height: 90vh !important;
        margin-bottom: 5% !important;

        @include media-breakpoint-down(md){
            width: 100% !important;
            max-width: 100% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content{
        height: 90vh !important;
    }
    .modal-body{
        overflow: scroll;
        flex-wrap: wrap;
    }

    .credit-usage-control{
        display: flex;
        flex-direction: column;
        width: 90%;

        .credit-usage-header{
            font-size: 18px;
            font-family: $fontMedium;
            color: var(--text-black-color);
        }


        .credit-usage-desc-control{
            display: flex;
            flex-direction: column;
        }

        .credit-usage-desc{
            font-size: 13px;
            font-family: $fontRegularLINE;
            color: var(--text-black-color);
        }
        .credit-usage-desc-bold{
            font-size: 13px;
            font-family: $fontMedium;
            color: var(--text-black-color);
        }

        .credit-usage-divider{
            margin-top: 14px;
            margin-bottom: 14px;
        }
    }
}