@import "../../../App.scss";


.talent-sauce-landing-main {

    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 2;
    background-color: black;


    .talent-sauce-landing-logo {
        width: 80px;
        height: 80px;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        margin-bottom: 16px;
    }



    .talent-sauce-landing-overlay-black {
        background-color: black;
        opacity: 0.6;
        min-height: 90vh;
        height: max-content;
        position: fixed;
        z-index: 2;
        width: 100vw;

        @media (min-width: 992px) and (min-height: 768px) {
            /* CSS styles to apply when the screen size matches */
            min-height: 100vh;
        }
        @media (min-width: 552px) and (max-height: 768px) {
            /* CSS styles to apply when the screen size matches */
            min-height: 95vh;
        }
        @include media-breakpoint-down(lg) {
            min-height: 100vh;
            height: auto;
            width: 100vw;
        }
        @media (max-width: 992px) and (max-height: 1200px) {
            min-height: 600px;
        }
        @include media-breakpoint-down(md) {
            min-height: 80vh;
            height: auto;
            opacity: 0.8;
            min-height: 100vh;
        }
    }

    .talent-sauce-landing-cover-logo-control {
        display: flex;
        margin-top: 16px;
        margin-bottom: 36px;
        justify-content: start;

        .talent-sauce-landing-cover-logo {
            cursor: pointer;
            height: 30px;
            width: auto;
            margin-left: 10px;
            margin-right: 10px;

            @include media-breakpoint-down(md) {
                height: 20px;
            }
        }
    }

    .talent-suace-landing-cover-button-start {
        background-color: transparent;
        color: var(--button-primary);
        border-radius: 44px;
        font-size: 18px;
        border: 1px solid var(--button-primary);
        width: 265px;
        height: 62px;
        font-family: $fontMedium;
        margin-left: 12px;
        margin-right: 12px;
        padding-left: 47px;
        padding-right: 47px;
        box-shadow: 0px 8px 12px 0px rgba(51, 51, 51, 0.12);

        &:hover {
            background-color: var(--button-primary);
            color: var(--text-white-color);
            border: none;
        }

        &:active {
            background-color: var(--button-primary) !important;
        }

        @include media-breakpoint-down(xl) {
            font-size: 15px;
            padding-left: 17px;
            padding-right: 17px;
            height: 51px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 13px;
            padding-left: 17px;
            padding-right: 17px;
        }

        @include media-breakpoint-down(md) {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 14px;
            font-size: 16px;
            height: 51px;
        }


    }

    .talent-suace-landing-cover-button-pricing {
        background-color: var(--button-primary);
        color: var(--text-white-color);
        border-radius: 44px;
        font-size: 18px;
        border: none;
        width: 235px;
        font-family: $fontMedium;
        height: 62px;
        margin-right: 12px;
        padding-left: 47px;
        padding-right: 47px;
        box-shadow: 0px 8px 12px 0px rgba(51, 51, 51, 0.12);

        &:hover {
            background-color: var(--button-primary);
            border: none;
        }

        &:active {
            background-color: var(--button-primary) !important;
        }

        @include media-breakpoint-down(xl) {
            font-size: 15px;
            padding-left: 17px;
            padding-right: 17px;
            height: 51px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 13px;
            padding-left: 17px;
            padding-right: 17px;
        }

        @include media-breakpoint-down(md) {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 14px;
            font-size: 16px;
            height: 51px;
        }
    }

    .talent-sauce-button-flex-control {
        display: flex;
        width: fit-content;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .talent-sauce-button-flex-control-custom {
        display: flex;
        width: auto;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-down(lg) {
            flex-direction: column;

        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .talent-suace-landing-orange-border {
        background-color: transparent;
        color: var(--button-primary);
        border-radius: 44px;
        font-size: 18px;
        border: 1px solid var(--button-primary);
        width: 235px;
        font-family: $fontMedium;
        height: 62px;
        margin-left: 6px;
        margin-right: 6px;
        margin-top: 24px;
        padding-left: 27px;
        padding-right: 27px;
        box-shadow: 0px 8px 12px 0px rgba(51, 51, 51, 0.12);

        &:hover {
            background-color: var(--button-primary);
            border: none;
        }

        &:active {
            background-color: var(--button-primary) !important;
        }

        @include media-breakpoint-down(xl) {
            font-size: 15px;
            padding-left: 17px;
            padding-right: 17px;
            height: 51px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 13px;
            padding-left: 17px;
            padding-right: 17px;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 14px;
            font-size: 16px;
            height: 51px;
        }
    }

    .talent-suace-landing-orange {
        background-color: var(--button-primary);
        color: var(--text-white-color);
        border-radius: 44px;
        font-size: 18px;
        border: none;
        width: 235px;
        font-family: $fontMedium;
        height: 62px;
        margin-left: 6px;
        margin-right: 6px;
        margin-top: 24px;
        padding-left: 27px;
        padding-right: 27px;
        box-shadow: 0px 8px 12px 0px rgba(51, 51, 51, 0.12);

        &:hover {
            background-color: var(--button-primary);
            border: none;
        }

        &:active {
            background-color: var(--button-primary) !important;
        }

        @include media-breakpoint-down(xl) {
            font-size: 15px;
            padding-left: 17px;
            padding-right: 17px;
            height: 51px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 13px;
            padding-left: 17px;
            padding-right: 17px;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 14px;
            font-size: 16px;
            height: 51px;
        }
    }

    .talent-sauce-carousel-mobile-control {
        display: none;
        z-index: 3;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .talent-sauce-carousel-control {
        display: block;
        z-index: 3;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .talent-sauce-landing-cover-button-control {
        display: flex;
        margin-top: 16px;
        justify-content: start;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    .talent-sauce-landing-cover-header-control {
        background: linear-gradient(180deg, #F26622 -3.97%, #2C3694 67.06%);
        display: flex;
        flex-direction: column;
        min-height: 90vh;
        width: 100%;

        position: relative;
        
        @media (min-width: 992px) and (min-height: 768px) {
            min-height: 100vh;
        }
       
        @include media-breakpoint-down(lg) {
            min-height: 100vh;
        }
        @media (max-width: 992px) and (max-height: 1200px) {
            min-height: 600px;
        }
        @media (max-width: 552px) and (max-height: 768px) {
            min-height: 95vh;
        }
        @include media-breakpoint-down(md) {
            min-height: 1000px;

        }

        @include media-breakpoint-down(sm) {
            min-height: 1000px;

        }

        .talent-sauce-landing-cover-img {
            position: absolute;
            background-image: url(../../../assets/images/techsauce/cover-landing.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            z-index: 0;
            mix-blend-mode: color-burn;
            opacity: 0.5;
        }

        .talent-sauce-landing-cover-grid-control {
            display: grid;
            grid-template-columns: 50% 50%;
            padding-left: 10%;
            padding-right: 10%;
            height: 60vh;
            padding-top: 2%;

            @media (max-width: 1440px) {
                padding-top: 2%;
            }
            
            @include media-breakpoint-down(lg) {
                padding-left: 2%;
                padding-right: 2%;
                padding-top: 1%;
                height: 70vh;
            }
            @media (max-width: 992px) and (max-height: 1200px) {
                padding-left: 2%;
                padding-right: 2%;
                padding-top: 1%;
                height: 600px;
            }
            @include media-breakpoint-down(md) {
                grid-template-columns: 100%;
                height: 100vh;
                padding-left: 2%;
                padding-right: 2%;
            }
        }

        .talent-sauce-landing-cover-header-text-control {
            display: flex;
            flex-direction: column;
            z-index: 3;
            margin-top: 5%;
            margin-bottom: auto;
            justify-content: center;

            @include media-breakpoint-down(lg) {
                margin-left: 5%;
                margin-right: 5%;
                margin-top: 2%;
            }

            @include media-breakpoint-down(md) {
                margin-left: 5%;
                margin-right: 5%;
                margin-top: 5%;
            }

            .talent-sauce-landing-cover-header-text-white-header {
                font-size: 40px;
                font-family: $fontMedium;
                color: var(--text-white-color);
                text-align: left;

                @include media-breakpoint-down(lg) {
                    font-size: 25px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 30px;
                }
            }

            .talent-sauce-landing-cover-header-text-white-header-2 {
                font-size: 35px;
                font-family: $fontMedium;
                color: var(--text-white-color);
                text-align: left;

                @include media-breakpoint-down(lg) {
                    font-size: 22px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 26px;
                }
            }

            .talent-sauce-landing-cover-header-textsub-gray {
                color: #B7B7D2;
                font-size: 16px;
                margin-top: 16px;
                font-family: $fontRegularLINE;

                @include media-breakpoint-down(md) {
                    font-size: 14px;
                    margin-top: 8px;
                }
            }

            .talent-sauce-landing-cover-header-text-orange-header {
                color: var(--text-primary-soft-color);
                font-size: 52px;
                font-family: $fontMedium;
                text-align: center;

                @include media-breakpoint-down(md) {
                    font-size: 40px;
                }
            }

            .talent-sauce-landing-cover-desc {
                font-size: 20px;
                color: var(--text-white-color);
                font-family: $fontRegularLINE;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-top: 14px;
                width: 50%;

                @include media-breakpoint-down(md) {
                    font-size: 16px;
                    width: 90%;
                }
            }

            .talent-sauce-landing-cover-special-offer-text {
                font-size: 20px;
                color: var(--text-white-color);
                font-family: $fontMedium;
                text-align: left;
                margin-top: 24px;
                width: auto;

                @include media-breakpoint-down(md) {
                    font-size: 16px;
                }
            }


        }

        .talent-sauce-landing-cover-t {
            position: absolute;
            margin-top: 5%;
            height: 110%;
            width: auto;
            object-fit: contain;
            z-index: 3;
            animation: opacityAndScale 1s infinite alternate;

            @include media-breakpoint-down(xl) {
                height: 80%;
                margin-top: 15%;
            }

            @include media-breakpoint-down(lg) {
                height: 50%;
                margin-top: auto;
                bottom: 0;
                top: 0;
                margin-bottom: auto;
            }

            @include media-breakpoint-down(md) {
                height: 40%;
                opacity: 0.5;
            }
        }

        .talent-sauce-landing-cover-s {
            position: absolute;
            margin-top: 12%;
            right: 0;
            margin-left: auto;
            height: 80%;
            width: auto;
            object-fit: contain;
            z-index: 3;
            animation: opacityAndScale 1s infinite alternate;


            @include media-breakpoint-down(xl) {
                height: 60%;
                margin-top: 25%;
            }

            @include media-breakpoint-down(lg) {
                height: 50%;
                margin-top: auto;
                bottom: 0;
                top: 0;
                margin-bottom: auto;
            }

            @include media-breakpoint-down(md) {
                height: 40%;
                opacity: 0.5;
            }
        }
    }

    .talent-sauce-landing-contact-control {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        justify-content: start;


        .talent-sauce-landing-contact-text {
            font-size: 20px;
            color: var(--text-white-color);
            font-family: $fontRegularLINE;

            @include media-breakpoint-down(lg) {
                text-align: center;
                font-size: 14px;
            }

            @include media-breakpoint-down(md) {
                text-align: center;
                font-size: 14px;
            }
        }

        .talent-sauce-landing-contact-text-bottom {
            font-size: 20px;
            color: var(--text-white-color);
            font-family: $fontRegularLINE;
            text-align: center;

            @include media-breakpoint-down(lg) {
                text-align: center;
                font-size: 14px;
            }

            @include media-breakpoint-down(md) {
                text-align: center;
                font-size: 14px;
            }
        }

        .talent-sauce-landing-contact-text-orange {
            font-size: 20px;
            margin-left: 6px;
            margin-right: 6px;
            color: #D45A1F;
            font-family: $fontMedium;
            cursor: pointer;
            text-decoration: underline;

            @include media-breakpoint-down(lg) {
                text-align: center;
                font-size: 14px;
            }

            @include media-breakpoint-down(md) {
                text-align: center;
                font-size: 14px;
            }
        }
    }

    .talent-sauce-landing-section-2-control {
        z-index: 3;
        margin-top: -2%;
        padding-top: 8%;
        padding-bottom: 8%;
        min-height: 800px;
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: column;
        background-image: url(../../../assets/images/techsauce/bg-wave.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;

        @include media-breakpoint-down(lg) {
            margin-top: 0%;
            min-height: 400px;

        }

        @include media-breakpoint-down(md) {
            margin-top: 0%;
            min-height: 600px;

        }

        @include media-breakpoint-down(sm) {
            margin-top: 0%;
            min-height: 600px;

        }

        .talent-sauce-landing-section-2-img {
            width: auto;
            height: 80px;
            margin-bottom: 16px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(sm) {
                height: 40px;
            }
        }

        .talent-sauce-landing-section-2-text-control {
            width: 60%;
            margin: auto;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(lg) {
                width: 90%;
            }

            @include media-breakpoint-down(md) {
                width: 90%;
            }
        }

        .talent-sauce-landing-section-2-header-text {
            font-size: 44px;
            color: var(--text-white-color);
            font-family: $fontMedium;
            text-align: center;

            @include media-breakpoint-down(lg) {
                font-size: 26px;
            }

            @include media-breakpoint-down(md) {
                font-size: 26px;
            }
        }

        .talent-sauce-landing-section-2-desc-text {
            font-size: 24px;
            color: var(--text-white-color);
            font-family: $fontRegularLINE;
            text-align: center;
            margin-top: 16px;

            @include media-breakpoint-down(lg) {
                font-size: 16px;
            }

            @include media-breakpoint-down(md) {
                font-size: 18px;
            }
        }
    }

    .talent-sauce-landing-section-3-control {
        z-index: 3;
        margin-top: -12%;
        padding-top: 8%;
        padding-bottom: 8%;
        min-height: 700px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-image: url(../../../assets/images/techsauce/wave-2-2.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        position: relative;

        @media (min-width: 552px) and (max-height: 768px) {
            /* CSS styles to apply when the screen size matches */
            margin-top: -10%;
            background-position: top left;
        }

        @include media-breakpoint-down(lg) {
            margin-top: -25%;
            background-position: top left;
        }
        @media (max-width: 992px) and (max-height: 1200px) {
            padding-bottom: 12%;
            margin-top: -25%;
        }
        @include media-breakpoint-down(md) {
            min-height: 900px;
            padding-top: 15%;
            padding-bottom: 12%;
            margin-top: -13%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: -20%;
            padding-bottom: 35%;
        }

        .talent-sauce-landing-section-3-product-control {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            z-index: 2;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(2, 1fr);
            }

            .talent-sauce-landing-section-3-product-img {
                width: 90%;
                height: auto;
                cursor: pointer;
                margin-left: auto;
                margin-right: auto;
            }

            .talent-sauce-landing-section-3-product-img-2 {
                width: 90%;
                height: auto;
                cursor: pointer;
                margin-top: 48px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .talent-sauce-landing-section-3-wave {
            position: absolute;
            background-image: url(../../../assets/images/techsauce/wave-2-2.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
            width: 100%;
            height: 400px;
            margin-top: -4%;

            @include media-breakpoint-down(lg) {
                margin-top: -20%;
                height: auto;
            }

            @include media-breakpoint-down(md) {
                margin-top: -20%;
            }
        }

        .talent-sauce-landing-section-3-grid-img-control {
            margin: auto;
            display: flex;

            margin-left: 5%;
            margin-right: 5%;
        }

        .talent-sauce-landing-section-3-grid-img {
            width: 100%;
            height: auto;
            object-fit: contain;
            margin: auto;

            @include media-breakpoint-down(md) {
                width: 80%;
            }
        }

        .talent-sauce-landing-section-3-wave-1 {
            position: absolute;
            width: 300px;
            height: 80px;
            object-fit: contain;
            margin-top: -5%;
            margin-left: -5%;
        }

        .talent-sauce-landing-section-3-wave-2 {
            position: absolute;
            width: 300px;
            height: 80px;
            object-fit: contain;
            right: 0;
            margin-left: auto;
            margin-bottom: -5%;
            margin-right: -5%;
            bottom: 0;

            @include media-breakpoint-down(lg) {
                margin-right: 0;
                margin-bottom: -5%;
                width: 100px;
            }
        }

        .talent-sauce-landing-section-3-grid-left {
            display: grid;
            grid-template-columns: 40% 60%;
            margin-bottom: 3%;
            margin-top: 3%;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            z-index: 3;
            position: relative;

            @include media-breakpoint-down(xl) {
                width: 80%;
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: 100%;
                width: 90%;
            }

            .talent-sauce-landing-section-3-text-control {
                display: flex;
                flex-direction: column;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 5%;
                width: 80%;

                @include media-breakpoint-down(xl) {
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }

                @include media-breakpoint-down(lg) {
                    padding-left: 36px;
                    padding-right: 36px;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    padding-left: 0px;
                    padding-right: 0px;
                }

                .talent-sauce-landing-section-3-text-white-header {
                    font-size: 36px;
                    font-family: $fontMedium;
                    color: var(--text-white-color);
                    text-align: left;

                    @include media-breakpoint-down(lg) {
                        font-size: 30px;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 26px;
                        text-align: center;
                    }

                }

                .talent-sauce-landing-section-3-text-orange-header {
                    color: var(--text-primary-soft-color);
                }

                .talent-sauce-landing-section-3-desc-text-white {
                    font-size: 18px;
                    font-family: $fontRegularLINE;
                    color: var(--text-white-color);
                    text-align: left;
                    margin-top: 16px;

                    @include media-breakpoint-down(lg) {
                        font-size: 18px;
                        padding-right: 16px;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        text-align: center;
                    }
                }
            }

        }

        .talent-sauce-landing-section-3-grid-right {
            display: grid;
            grid-template-columns: 60% 40%;
            margin-bottom: 5%;
            margin-top: 5%;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            z-index: 3;
            position: relative;

            @include media-breakpoint-down(xl) {
                width: 90%;
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: 100%;
                width: 90%;
            }




            .talent-sauce-landing-section-3-text-control {
                display: flex;
                flex-direction: column;
                width: 80%;
                margin-top: auto;
                margin-bottom: auto;

                @include media-breakpoint-down(xl) {
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }


                .talent-sauce-landing-section-3-text-white-header {
                    font-size: 36px;
                    font-family: $fontMedium;
                    color: var(--text-white-color);
                    text-align: right;

                    @include media-breakpoint-down(lg) {
                        font-size: 30px;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 26px;
                        text-align: center;
                    }
                }

                .talent-sauce-landing-section-3-text-orange-header {
                    color: var(--text-primary-soft-color);
                }

                .talent-sauce-landing-section-3-desc-text-white {
                    font-size: 20px;
                    font-family: $fontRegularLINE;
                    color: var(--text-white-color);
                    text-align: right;
                    margin-top: 16px;

                    @include media-breakpoint-down(lg) {
                        font-size: 18px;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        text-align: center;
                    }
                }
            }

        }
    }

    .talent-sauce-landing-last-wave {
        width: 100vw;
        position: absolute;
        height: 100px;
        background-image: url(../../../assets/images/techsauce/wave-5-1.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        bottom: 0;
        margin-top: auto;

        @include media-breakpoint-down(lg) {
            height: 70px;
        }

        @include media-breakpoint-down(sm) {
            height: 50px;
        }
    }

    .talent-sauce-landing-last-wave-back {
        width: 100vw;
        position: absolute;
        height: 120px;
        background-image: url(../../../assets/images/techsauce/wave-5-2.png);
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.3;
        background-position: top center;
        bottom: 0;
        margin-top: auto;

        @include media-breakpoint-down(lg) {
            height: 70px;
        }

        @include media-breakpoint-down(sm) {
            height: 50px;
        }
    }
    .talent-sauce-landing-section-5-company-control {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        width: 60%;
        z-index: 3;
        @include media-breakpoint-down(xl){
            width: 80%;
        }
        @include media-breakpoint-down(lg){
            width: 100%;
        }
        @include media-breakpoint-down(sm){
            width: 100%;
        }

        .talent-sauce-landing-section-5-company-title {
            margin-top: 16px;
            font-size: 18px;
            font-family: $fontMedium;
            color: var(--text-white-color);
            margin-left: auto;
            text-align: center;
            margin-right: auto;
            margin-bottom: 16px;
        }

        .talent-sauce-landing-section-5-company-img{
            width: 100%;
            height: auto;
            object-fit: contain;
            margin-top: 24px;
        }


        
    }
    .talent-sauce-landing-section-5-control {
        z-index: 3;
        margin-top: -2%;
        padding-top: 8%;
        padding-bottom: 8%;
        min-height: 700px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-image: url(../../../assets/images/techsauce/wave-4-1.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        position: relative;

        @include media-breakpoint-down(md) {
            margin-top: -2%;
            min-height: 500px;
            padding-bottom: 12%;
            padding-top: 11%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0px;
            min-height: 400px;
            padding-bottom: 12%;
            padding-top: 40%;
            background-position: center center;
        }
        

        

        .talent-sauce-landing-section-5-text-control {
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
            display: flex;
            flex-direction: column;
            width: 60%;
            z-index: 3;

            @include media-breakpoint-down(xl) {
                margin-top: 15%;
            }

            @include media-breakpoint-down(lg) {
                margin-top: 10%;
                width: 80%;
            }

            @include media-breakpoint-down(md) {
                margin-top: 10%;
                width: 90%;
            }

            @include media-breakpoint-down(sm) {}

            .talent-sauce-landing-section-5-pricing-control {
                background-color: black;
                border-radius: 12px;
                padding: 24px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 24px;
                display: flex;
                position: relative;
                flex-direction: column;

                @include media-breakpoint-down(xl) {
                    margin-top: 15%;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 10%;
                }


                .talent-sauce-landing-section-5-pricing-line-wave {
                    top: 0;
                    width: 100%;
                    object-fit: contain;
                    height: 40px;
                    position: absolute;
                    margin-top: -24px;

                    @include media-breakpoint-down(md) {
                        width: 90%;
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                    }
                }

                .talent-sauce-landing-section-5-pricing-curve-wave {
                    bottom: 0;
                    margin-top: auto;
                    margin-left: -8%;
                    width: 124px;
                    object-fit: contain;
                    height: 124px;
                    position: absolute;

                    @include media-breakpoint-down(md) {
                        width: 80px;
                        height: 80px;
                        margin-left: -15%;
                    }
                }

                .talent-sauce-landing-section-5-pricing-text {
                    font-size: 18px;
                    font-family: $fontRegularLINE;
                    color: var(--text-white-color);
                    margin-left: auto;
                    text-align: center;
                    margin-right: auto;
                }

                .talent-sauce-landing-section-5-pricing-special-offer {
                    margin-top: 16px;
                    font-size: 18px;
                    font-family: $fontMedium;
                    color: var(--text-white-color);
                    margin-left: auto;
                    text-align: center;
                    margin-right: auto;
                    margin-bottom: 16px;
                }
            }

            .talent-sauce-landing-section-5-text {
                font-size: 24px;
                font-family: $fontRegularLINE;
                color: var(--text-white-color);
                text-align: center;
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 16px;

                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                    width: 100%;
                }

                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
            }
        }
    }

    .talent-sauce-landing-section-4-control {
        z-index: 3;
        margin-top: -8%;
        padding-top: 8%;
        padding-bottom: 8%;
        min-height: 700px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-image: url(../../../assets/images/techsauce/wave-3-1.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        position: relative;

        @include media-breakpoint-down(md) {
            margin-top: -8%;
            min-height: 500px;
            padding-bottom: 12%;
            padding-top: 11%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: -35%;
            min-height: 900px;
            padding-bottom: 12%;
            padding-top: 40%;
            background-position: center center;
        }


        .talent-sauce-landing-section-5-text-control {
            margin-left: auto;
            margin-right: auto;
            margin-top: 10%;
            display: flex;
            flex-direction: column;
            width: 60%;
            z-index: 3;

            @include media-breakpoint-down(xl) {
                margin-top: 15%;
            }

            @include media-breakpoint-down(lg) {
                margin-top: 10%;
                width: 80%;
            }

            @include media-breakpoint-down(md) {
                margin-top: 10%;
                width: 90%;
            }

            @include media-breakpoint-down(sm) {}

            .talent-sauce-landing-section-5-pricing-control {
                background-color: black;
                border-radius: 12px;
                padding: 24px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 24px;
                display: flex;
                position: relative;
                flex-direction: column;

                @include media-breakpoint-down(xl) {
                    margin-top: 15%;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 10%;
                }


                .talent-sauce-landing-section-5-pricing-line-wave {
                    top: 0;
                    width: 100%;
                    object-fit: contain;
                    height: 40px;
                    position: absolute;
                    margin-top: -24px;

                    @include media-breakpoint-down(md) {
                        width: 90%;
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                    }
                }

                .talent-sauce-landing-section-5-pricing-curve-wave {
                    bottom: 0;
                    margin-top: auto;
                    margin-left: -8%;
                    width: 124px;
                    object-fit: contain;
                    height: 124px;
                    position: absolute;

                    @include media-breakpoint-down(md) {
                        width: 80px;
                        height: 80px;
                        margin-left: -15%;
                    }
                }

                .talent-sauce-landing-section-5-pricing-text {
                    font-size: 18px;
                    font-family: $fontRegularLINE;
                    color: var(--text-white-color);
                    margin-left: auto;
                    text-align: center;
                    margin-right: auto;
                }

                .talent-sauce-landing-section-5-pricing-special-offer {
                    margin-top: 16px;
                    font-size: 18px;
                    font-family: $fontMedium;
                    color: var(--text-white-color);
                    margin-left: auto;
                    text-align: center;
                    margin-right: auto;
                    margin-bottom: 16px;
                }
            }

            .talent-sauce-landing-section-5-text {
                font-size: 24px;
                font-family: $fontRegularLINE;
                color: var(--text-white-color);
                text-align: center;
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 16px;

                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                    width: 100%;
                }

                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
            }
        }
    }
}

@keyframes opacityAndScale {
    0% {
        opacity: 0.8;
        transform: scale(0.95);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}