@import "../App.scss";

.candidate-history-modal-main{

    
    .modal-dialog {
        max-width: 90% !important;
        width: 90% !important;
        height: 90vh !important;
        margin-bottom: 5% !important;
        @include media-breakpoint-down(xl){
            width: 100% !important;
            max-width: 100% !important;
            margin-left: auto;
            margin-right: auto;
        }
        @include media-breakpoint-down(md){
            width: 100% !important;
            max-width: 100% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content{
        height: 90vh !important;
    }
    .modal-body{
        overflow: scroll;
        flex-wrap: wrap;
    }

}