@import "../App.scss";


.card-recommend-modal-main {
    background-color: #00000070 !important;

    .modal-dialog {
        max-width: 80% !important;
        width: 80% !important;

        @include media-breakpoint-down(md) {
            width: 100% !important;
            max-width: 100% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content {
        background-color: #00000070 !important;
        border: none;
        margin-top: auto;
        margin-bottom: auto;
    }

    .card-recommend-loading-control {
        min-height: 500px;
        width: 100%;
        display: flex;

        .card-recommend-loading {
            margin: auto;
            display: flex;
            flex-direction: column;

            .card-recommend-loading-img {
                width: 140px;
                height: 140px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
                animation: bounce 2s infinite;
            }

            .card-recommend-loading-text {
                margin-top: 24px;
                font-size: 18px;
                color: var(--text-white-color);
                font-family: $fontMedium;
                text-align: center;
            }
        }
    }

    .card-recommend-main-control {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        position: relative;
        background-color: #00000070 !important;

        .card-recommend-main-title {
            font-size: 24px;
            font-family: $fontMedium;
            color: white;
            text-align: center;
        }

        .card-recommed-close-button {
            position: absolute;
            right: 0;
            top: 0;
            margin-right: 36px;
            margin-top: 24px;
            font-size: 36px;
            color: white;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                margin-top: 12px;
                margin-right: 12px;
            }
        }

    }

    .card-recommend-item-control {
        margin-top: 36px;
        display: flex;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        flex-wrap: wrap;
        justify-content: center;

        @include media-breakpoint-down(xl) {
            width: 100%;
        }

    }


    .card-recommend-item-card {
        margin-bottom: 24px;
        margin-left: 16px;
        margin-right: 16px;

        @include media-breakpoint-down(xl) {
            margin-left: 8px;
            margin-right: 8px;
        }

        .card {
            width: 350px;
            height: 350px;
            perspective: 1000px;
            border-radius: 8px;
            border: none;
            margin-left: auto;
            margin-right: auto;
            background-color: transparent !important;

            @media (min-width: 1601px) {
                width: 350px;
                height: 350px;
            }
            @media (max-width: 1600px) {
                width: 280px;
                height: 350px;
            }
            @media (max-width: 1400px) {
                width: 250px;
                height: 350px;
            }


        }

        // .card-inner {
        //     width: 100%;
        //     height: 100%;
        //     transform-style: preserve-3d;
        //     transition: transform 0.5s;
        // }

        // .card:hover .card-inner {
        //     transform: rotateY(180deg);
        // }

        .card-front,
        .card-back {
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
        }

        .card-front {
            cursor: pointer;
            background: var(--primary-color);
            box-shadow: 0px 0px 5px var(--border-input);
            border: none;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;


            .card-front-profile-control {
                display: flex;
                flex-direction: column;

                .card-front-profile-img {
                    width: 150px;
                    height: 150px;
                    object-fit: contain;
                    margin-left: auto;
                    margin-right: auto;
                }

                .card-front-job-title {
                    font-size: 18px;
                    margin-top: 12px;
                    color: white;
                    text-align: center;
                    font-family: $fontMedium;
                }
                .card-front-job-last-exp {
                    font-size: 14px;
                    margin-top: 24px;
                    color: white;
                    text-align: center;
                    font-family: $fontRegular;
                }
                .card-front-job-click-open {
                    font-size: 13px;
                    margin-bottom: 24px;
                    text-decoration: underline;
                    color: white;
                    text-align: center;
                    font-family: $fontRegular;
                }
            }
        }



        .card-back {
            background-color: white !important;
            box-shadow: 0px 0px 5px var(--border-input);
            border: none;
            border-radius: 8px;
            transform: rotateY(180deg);
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 8px 24px;
        }

        .card-back-open {
            background: var(--primary-color);
            box-shadow: 0px 0px 5px var(--border-input);
            border: none;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            height: 350px;
            padding: 8px 24px;
            animation: flip 1s forwards;

        }

        .card-back-active {
            background: white;
            box-shadow: 0px 0px 5px var(--border-input);
            border: none;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            height: 350px;
            padding: 8px 24px;
            animation: flip 1s forwards;
        }

        .card-back-profile-control {
            display: flex;
            height: 100%;
            flex-direction: column;
            transform: rotateY(180deg);

            .card-back-profile-img {
                width: 100px;
                height: auto;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }

            .card-back-title {
                font-size: 13px;
                color: var(--text-gray-color);
                font-family: $fontRegular;
                margin-top: 16px;

            }

            .card-back-desc {
                font-size: 12px;
                color: var(--text-black-color);
                font-family: $fontMedium;
                margin-left: 8px;
            }

            .card-back-desc-skill {
                margin-left: 8px;
                font-size: 11px;
                color: var(--text-black-color);
                font-family: $fontMedium;
            }
            .card-back-button-control{
                display: flex;
                margin-top: auto;
            }
            .card-back-button-cancle {
                margin-top: auto;
                background-color: white;
                border-radius: 8px;
                border: 2px solid  var(--border-input);;
                width: 95%;
                margin-right: 4px;
                height: 41px;
                font-size: 13px;
                display: flex;
                color: var(--text-gray-color);
                font-family: $fontMedium;

                &:hover{
                    color: white;
                    background-color: var(--border-input);
                }

                .card-back-button-coin {
                    font-size: 13px;
                    margin-right: 12px;
                    margin-top: auto;
                    margin-bottom: auto;
                    color: var(--pro-color);
                }
            }
            .card-back-button-open {
                margin-top: auto;
                background-color: var(--button-primary);
                border-radius: 8px;
                border: none;
                width: 100%;
                height: 41px;
                font-size: 13px;
                display: flex;
                color: var(--text-button-color);
                font-family: $fontMedium;

                .card-back-button-coin {
                    font-size: 13px;
                    margin-right: 12px;
                    margin-top: auto;
                    margin-bottom: auto;
                    color: var(--pro-color);
                }
            }
        }

    }

    @keyframes flip {
        0% {
            transform: rotateY(0deg);
            background-color: var(--primary-color);
        }

        100% {
            transform: rotateY(180deg);
            background-color: white;
        }

    }

    @keyframes bounce {
        0% {
            transform: translateY(0);
            /* Start and end position: no vertical movement */
        }

        50% {
            transform: translateY(-15px);
            /* Bounce downwards */
        }

        100% {
            transform: translateY(0px);
            /* Bounce downwards */
        }
    }
}