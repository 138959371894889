@import "../../App.scss";


.landing-component {
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    
    @include media-breakpoint-down(md){
        padding: 0px !important;
    }

    .landing-button-control{
        background-color: var(--button-primary) !important;
        border: none;
    }


    .landing-component-cover-control {

        display: grid;
        grid-template-columns: 50% 50%;
        min-height: 800px;
        @include media-breakpoint-down(lg) {
            grid-template-columns: 60% 40%;
            min-height: 600px;
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
            min-height: 700px;
            padding-left: 2%;
            padding-right: 2%;

        }
        @include media-breakpoint-down(sm) {
            grid-template-columns: 100%;
            min-height: 650px;
            padding-left: 2%;
            padding-right: 2%;

        }

        .landing-component-cover-text-control {
            display: flex;
            flex-direction: column;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(md) {
                order: 1;
            }

            .landing-component-cover-text-headline-border {
                width: 200px;
                height: auto;
                object-fit: contain;
            }

            .landing-component-cover-text-headline {
                font-size: 42px;
                width: 70%;
                font-family: $fontMedium;
                color: var(--text-black-color);
                word-spacing: 0.2em;
                @include media-breakpoint-down(lg){
                    width: 80%;
                    font-size: 36px;
                }
                @include media-breakpoint-down(md){
                    width: 100%;
                    font-size: 22px;
                    text-align: center;
                }
            }

            .landing-component-cover-text-desc {
                font-size: 20px;
                width: 90%;
                margin-top: 12px;
                font-family: $fontRegular;
                color: var(--text-gray-color);

                @include media-breakpoint-down(md){
                    width: 100%;
                    text-align: center;
                    font-size: 17px;
                }
            }
            .landing-component-cover-why-text {
                font-size: 14px;
                color: var(--text-black-color);
                text-decoration: underline;
                color: $fontRegular;
                margin-top: auto;
                margin-bottom: auto;
                margin-top: 24px;
                cursor: pointer;

                @include media-breakpoint-down(md){
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .landing-component-cover-bottom-control {
                margin-top: 24px;
                display: flex;

                @include media-breakpoint-down(md){
                    margin-left: auto;
                    margin-right: auto;
                    display: grid;
                    grid-template-columns: 100%;
                }
                .landing-component-cover-bottom-text {
                    font-size: 14px;
                    color: var(--text-black-color);
                    text-decoration: underline;
                    color: $fontRegular;
                    margin-left: 24px;
                    margin-top: auto;
                    margin-bottom: auto;
                    cursor: pointer;

                    @include media-breakpoint-down(md){
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 16px;
                        margin-bottom: 16px;
                    }
                }

                .landing-component-button-login{
                    margin-left: 24px;

                    @include media-breakpoint-down(md){
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
             
            }
        }
        



        .landing-component-cover-img {
            width: 80%;
            height: auto;
            margin: auto;
            object-fit: contain;

            @include media-breakpoint-down(lg){
                height: 180px;
                width: auto;
                z-index: 0;
                object-fit: cover;
             
            }

            @include media-breakpoint-down(md) {
                order: 0;
                width: auto;
                height: 200px;
                margin-left: auto;
                margin-bottom: auto;
                margin-bottom: 0;
            }
        }
    }

    .landing-component-section-2-control {
        padding-top: 32px;
        padding-bottom: 32px;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md){
            padding-left: 2%;
            padding-right: 2%;
        }

        .landing-component-section-2-headline-border {
            width: 200px;
            height: auto;
            object-fit: contain;
        }

        .landing-component-section-2-headline {
            font-size: 48px;
            color: var(--text-black-color);
            text-align: center;
            font-family: $fontMedium;
        }

        .landing-component-section-2-desc {
            font-size: 22px;
            margin-top: 16px;
            color: var(--text-black-color);
            text-align: center;
            font-family: $fontRegular;

            
            @include media-breakpoint-down(md){
                text-align: center;
                font-size: 16px;
            }
        }

        .landing-component-section-2-img {
            margin-top: 48px;
            width: 40%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            object-fit: contain;

            @include media-breakpoint-down(md){
                width: auto;
                height: 150px;
            }
        }
    }

    .landing-component-section-3-control {
        padding-top: 32px;
        padding-bottom: 32px;
        display: grid;
        width: 100%;
        grid-template-columns: 50% 50%;

        @include media-breakpoint-down(md){
            grid-template-columns: 100%;
            padding-left: 2%;
            padding-right: 2%;
        }

        .landing-component-section-3-text-control {
            display: flex;
            flex-direction: column;
            width: 90%;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(md){
                width: 100%;
            }

            .landing-component-section-3-headline-img {
                width: auto;
                height: 48px;
                object-fit: contain;
                margin-right: auto;
                @include media-breakpoint-down(lg){
                    height: 36px;
                  
                }
                @include media-breakpoint-down(md){
                    height: 24px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .landing-component-section-3-headline {
                font-size: 48px;
                color: var(--text-black-color);
                text-align: left;
                font-family: $fontMedium;
            }

            .landing-component-section-3-desc {
                font-size: 22px;
                margin-top: 16px;
                color: var(--text-black-color);
                text-align: left;
                font-family: $fontRegular;
                @include media-breakpoint-down(lg){
                    font-size: 15px;
                }
                @include media-breakpoint-down(md){
                    text-align: center;
                    font-size: 16px;
                }
            }
        }

        .landing-component-section-3-img-right {
            width: 90%;
            height: auto;
            object-fit: contain;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
            margin-right: -15%;
            @include media-breakpoint-down(lg){
                margin-right: auto;
                width: auto;
                height: 200px;
            }
            @include media-breakpoint-down(md){
                margin-right: auto;
                width: auto;
                height: 150px;
                margin-bottom: 48px;
            }
        }

        .landing-component-section-3-img-left {
            width: 90%;
            height: auto;
            object-fit: contain;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: auto;
            margin-left: -15%;
            @include media-breakpoint-down(md){
                margin-left: auto;
                    width: auto;
                    height: 150px;
                    margin-bottom: 48px;
            }
        }
    }

    .landing-button-feature{
        min-width: 30%;
        margin-top: 16px;
    }
}