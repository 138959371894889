@import "../../App.scss";

.history-main{
    display: flex;
    flex-direction: column;

    .Mui-selected{
        color: var(--primary-color) !important;
        font-family: $fontMedium;
    }
    .history-main-tab-custom{
        color: var(--primary-color);
        margin-bottom: 24px;
    }
}