@import "../../../../../App.scss";

.campaign-detail-modal {

    .modal-dialog {
        max-width: 60% !important;
        width: 60% !important;
    }
}

.campaign-detail-control {

    display: flex;
    flex-direction: column;

    .campaign-detail-link-more-control{
        display: flex;
        justify-content: center;
        cursor: pointer;

        .campaign-detail-link-more{
            text-decoration: underline;
            color: var(--link-color);
            font-size: 13px;
            font-family: $fontRegular;
            margin-top: auto;
            margin-bottom: auto;
        }

        .campaign-detail-link-more-icon{
            color: var(--link-color);
            font-size: 13px;
            font-family: $fontRegular;
            margin-top: auto;
            margin-left: 4px;
            margin-bottom: auto;
        }
    }
   

    .MuiTableCell-root {
        font-family: $fontMedium !important;
        color: var(--text-black-color);
    }

    .campaign-detail-title {
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-black-color);
     
    }

    .campaign-more-detail-control-main {
        display: flex;
        padding: 12px 24px;
        background-color: rgba(0, 0, 0, 0.04);
        flex-direction: column;
        width: 100%;
    }
    .campaign-more-detail-title {
        font-size: 13px;
        font-family: $fontMedium;
        color: var(--text-black-color);
        border-bottom: none;
        margin-top: 12px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .campaign-more-detail-desc-control{
        border-bottom: none;
    }

    .campaign-more-detail-divider{
        width: 100%;
        height: 1px;
        padding: 0px;
        margin-top: 6px;
        margin-bottom: 6px;
        background-color: #888;
    }

    .campaign-more-detail-desc {
        
        font-size: 13px;
        font-family: $fontRegular !important;
        color: var(--text-black-color);
    }
    .campaign-more-detail-control {
        display: flex;
        flex-direction: column;

       
    }

    .campaign-detail-border-control {
        margin-top: 16px;
        border: 1px solid var(--border-input);
        border-radius: 5px;
        padding: 8px 12px;
        display: flex;
        flex-direction: column;
    }

    .campaign-detail-tag-control {
        display: flex;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .campaign-detail-more-detail-link {
        color: var(--link-color);
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
        font-family: $fontMedium;
        margin-left: auto;
        margin-right: auto;
    }


    .campaign-detail-tag-value {
        cursor: pointer;
        font-size: 14px;
        font-family: $fontRegular;
        color: var(--text-white-color);
        background-color: var(--primary-color);
        padding: 10px 12px;
        border-radius: 26px;
        margin-right: 4px;
    }

    .campaign-detail-table-grid-control {
        margin-top: 16px;
        border: none;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 12px 6px;
    }

    .campaign-detail-divider-width {
        height: 3px;
        background-color: var(--border-input);
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .campaign-detail-insight-border {
        border: 1px solid #bbbbbb;
        padding: 6px 6px;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 50% 50%;
        margin-bottom: 16px;

        .campaign-detail-insight-divier {
            width: 100%;
            height: 1px;
            background-color: #bbbbbb;
            margin-top: 4px;
            margin-bottom: 4px;
        }

        .campaign-insight-detail-headline {
            margin-top: 6px;
            margin-bottom: 6px;
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--text-black-color);
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

        .campaign-insight-detail-desc {
            font-size: 13px;
            margin-top: 6px;
            margin-bottom: 6px;
            font-family: $fontRegular;
            color: var(--text-black-color);
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    .campaign-detail-headline {
        font-size: 14px;
        font-family: $fontMedium;
        color: var(--text-black-color);
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .campaign-detail-desc {
        font-size: 13px;
        font-family: $fontRegular;
        color: var(--text-black-color);
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .campaign-detail-table-grid {
        margin-top: 12px;
        margin-bottom: 12px;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;


    }

}

.campaign-detail-loading-control {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;

    .campaign-detail-loading-progress-control {
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .campaign-detail-loading-progress {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }

    .campaign-detail-loading-text {
        font-size: 15px;
        color: var(--text-black-color);
        font-family: $fontRegular;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
    }
}