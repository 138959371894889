@import "../../../App.scss";


.privacy-page-main{
    width: 100vw;
    min-height: 100vh;
    padding: 2% 5%;
    display: flex;
    flex-direction: column;


    .privacy-page-icon{
        width: auto;
        height: 70px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }

    .privacy-content-control{
        width: 80%;
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;

        .privacy-content-title{
            font-size: 16px;
            font-family: $fontMedium;
            color: var(--text-black-color);
        }
        .privacy-content-desc{
            font-size: 13px;
            font-family: $fontRegular;
            color: var(--text-black-color);
        }

        .privacy-content-bold{
            font-size: 14px;
            font-family: $fontMedium;
            color: var(--text-black-color);
        }
    }


}