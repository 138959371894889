@import "../../../App.scss";


.talent-search-control{
    width: auto;
    height: 90vh;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md){
        height: 90vh;
    }

    .feature-credit-usage-main-control{
        display: flex;
        margin-bottom: 12px;

        @include media-breakpoint-down(sm){
            flex-direction: column;
            margin-bottom: 12px;
        }
    }

    .feature-credit-usage-control{

        display: flex;
        margin-top: 16px;
        margin-left: 30px;

        @include media-breakpoint-down(md){
            margin-left: 0px;
            margin-top: 0px;
            margin-bottom: 12px;
        }

        .feature-credit-usage-icon{
            font-size: 16px;
            color: rgb(255, 183, 0);
        }

        .feature-credit-usage-text{
            font-size: 14px;
            color: var(--text-black-color);
            margin-left: 8px;
            font-family: $fontRegularLINE;
        }
        .feature-credit-usage-text-bold{
            font-size: 14px;
            color: var(--text-black-color);
            margin-left: 8px;
            font-family: $fontMedium;
        }
    }

    .talent-search-button-control{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;

        @include media-breakpoint-down(md){
            margin-left: auto;
            margin-right: auto;
            display: grid;
            width: 100%;
            grid-template-columns: 50% 50%;
        }
    }
    .button-history-talent-search{
        background-color: var(--button-primary);
        min-width: 200px;
        height: 41px;
        border-radius: 4px;
        color: var(--text-white-color);
        font-size: 14px;
        font-family: $fontMedium;
        margin-right: 6px;
        border: none;
       

        &:hover{
            background-color: var(--button-primary);
            box-shadow:  0px 0px 5px var(--border-input);
        }

        @include media-breakpoint-down(md){
            width: 99%;
            min-width: auto;
        }
    }
    .button-home-talent-search{
        background-color: white;
        min-width: 200px;
        height: 41px;
        border-radius: 4px;
        color:  var(--button-primary);
        font-size: 14px;
        font-family: $fontMedium;
        margin-right: 6px;
        border: 1px solid  var(--button-primary);
       

        &:hover{
            background-color: white;
            color:  var(--button-primary);
            box-shadow:  0px 0px 5px var(--border-input);
            border: 1px solid  var(--button-primary);
        }

        &:active{
            background-color: white !important;
            color:  var(--button-primary) !important;
        }

        @include media-breakpoint-down(md){
       
            width: 99%;
            min-width: auto;
        }
    }
}