@import "../../../../../App.scss";

.audience-import-modal{

    .modal-dialog {
        max-width: 80% !important;
        width: 80% !important;

        @include media-breakpoint-down(md){
            width: 90% !important;
            max-width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.audience-import-main{
    display: flex;

    .audience-import-main-control{

        display: flex;
        flex-direction: column;
        padding: 12px 24px;
        width: 100%;

        @include media-breakpoint-down(md){
            padding: 12px 4px;
        }

        .MuiOutlinedInput-input{
            height: 1em !important;
            font-family: $fontRegular !important;
            padding: 12px 6px;
           
        }

        .audience-import-required-error{
            font-size: 12px;
            font-family: $fontRegular;
            color: red;
        }

        .audience-import-name-control{
            display: flex;
            flex-direction: column;
            width: 50%;
            margin-bottom: 24px;
            margin-top: 6px;

            @include media-breakpoint-down(md){
                width: 100%;
            }
        }
        .audience-import-sheet-control{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 24px;
            margin-top: 6px;

            @include media-breakpoint-down(md){
                width: 100%;
            }

            .audience-import-sheet-title{
                cursor: pointer;
                margin-left: 12px;
                color: var(--primary-color);
                font-size: 14px;
                font-family: $fontMedium;
                
                &:hover{
                    text-decoration: underline;
                }
            }
        }

        .audience-import-total-count{
            font-size: 28px;
            font-family: $fontMedium;
            color: var(--primary-color);
            margin-left: 16px;
        }

        .audience-import-main-item-grid{
            display: grid;
            grid-template-columns: 50% 50%;

            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
            }

            .MuiFormControlLabel-label{
                font-size: 11px !important;
                font-family: $fontRegular !important;
            }

            .audience-import-main-item-grid-title{
                font-size: 14px;
                font-family: $fontRegular !important;
            }
        }

        .audience-import-divider{
            width: 100%;
            margin-top: 8px;
            margin-bottom: 8px;
            height: 1px;
            background-color: var(--border-input);
        }

        .audience-import-title{
            font-size: 16px;
            font-family: $fontMedium;
            color: var(--text-black-color);
        }

        .audience-import-main-grid{
            display: grid;
            grid-template-columns: 50% 50%;
            width: 100%;
            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
            }

            .audience-import-main-item-control{
                margin-top: 16px;
                display: flex;
                flex-direction: column;

                .audience-import-main-item-title{
                    font-size: 14px;
                    font-family: $fontMedium;
                    color: var(--text-black-color);
                }
            }
        }

        .audience-main-button-submit{
            font-size: 15px;
            color: var(--text-button-color);
            background-color: var(--button-primary);
            border-radius: 8px;
            min-width: 20%;
            width: 30%;
            margin-right: 16px;
            height: 41px;
            padding: auto 14px;
            margin-top: 23px;
    
            &:hover{
                background-color: var(--primary-color);
            }

            @include media-breakpoint-down(md){
                min-width: 90%;
            }
        }
       
    }

    .audience-import-loading-control{
        height: 500px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
        .audience-import-loading-progress-control{
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .audience-import-loading-progress{
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }
    
        .audience-import-loading-text{
            font-size: 15px;
            color: var(--text-black-color);
            font-family: $fontRegular;
            margin-top: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    
}