@import "../../../App.scss";


.loading-comp{


    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
    height: 100%;

    .loading-control{
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        flex-direction: column;
        display: flex;

        .loading-lottie{
            width: 150px;
            height: 150px;
            margin-left: auto;
            margin-right: auto;

        }

        .loading-text{
            margin-left: auto;
            margin-right: auto;
            font-size: 18px;
            color: var(--text-black-color);
            font-family: $fontMedium;
        }

        .loading-trick-text{
            margin-top: 60px;
            width: 70%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            font-size: 15px;
            color: var(--text-black-color);
            font-family: $fontRegular;
        }
    }
}