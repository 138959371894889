@import "../../../../../App.scss";


.campaign-list-control {
    display: flex;
    flex-direction: column;
    width: 100%;

    .MuiTableCell-root {
        font-family: $fontMedium !important;
        color: var(--text-black-color);
    }
    .campaign-button-view {
        width: 50%;
        height: 45px;
        border-radius: 8px;
        background-color: var(--button-primary);
        color: var(--text-button-color);
        font-size: 12px;
        font-family: $fontMedium;

        &:hover{
            background-color: var(--button-primary);
        }
    }
    .campaign-list-loading-control {
        height: 500px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;

        .campaign-list-loading-progress-control {
            margin: auto;
            display: flex;
            flex-direction: column;
        }

        .campaign-list-loading-progress {
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

       


        .campaign-list-loading-text {
            font-size: 15px;
            color: var(--text-black-color);
            font-family: $fontRegular;
            margin-top: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .campaign-list-button-create {
        width: 300px;
        height: 41px;
        border-radius: 26px;
        background-color: var(--primary-color);
        color: white;
        font-family: $fontMedium;
        font-size: 14px;
        margin-bottom: 16px;
        margin-left: auto;

        &:hover {
            background-color: var(--primary-color);
        }
    }
}