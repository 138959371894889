@import "../../App.scss";


.register-main{

    width: 100%;
    min-height: 100vh;
    display: flex;

    .register-main-control{
        display: flex;
        flex-direction: column;
        padding: 2% 5%;
        width: 50%;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        min-height: 600px;
        border-radius: 15px;
        box-shadow: 0px 0px 5px var(--border-input);
        @include media-breakpoint-down(xl){
            width: 70%;
        }

        @include media-breakpoint-down(md){
            width: 90%;
        }


        .register-title{
            font-size: 16px;
            font-family: $fontMedium;
            color: var(--text-black-color);
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
        }

        .register-logo{
            width: 150px;
            height: auto;
            object-fit: contain;
            margin: 6px auto;
        }

        .register-grid-form{
            display: grid;
            grid-template-columns: 50% 50%;
            margin-top: 24px;

            @include media-breakpoint-down(md){
                grid-template-columns: 100%;
            }

            .register-item-control{
                display: flex;
                flex-direction: column;
                width: 90%;
                margin-top: 24px;
                @include media-breakpoint-down(md){
                    width: 100%;
                }
            }

            .register-item-label{
                font-size: 13px;
                font-family: $fontRegular;
                color: var(--text-gray-color);
            }

            .register-item-input-form{
                height: 45px;
                width: 100%;
                font-size: 14px;
                font-family: $fontMedium;
                border-color: var(--border-input);

                &:focus{
                    box-shadow: var(--primary-color);
                }
            }

           
        }

        .register-terms-title{
            margin-top: 24px;
            font-size: 11px;
            color: var(--text-gray-color);
            font-family: $fontRegular;
            text-align: center;

            .register-terms-link{
                font-size: 12px;
                color: var(--link-color);
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .register-button-submit{
            margin: 24px auto;
            width: 40%;
            height: 45px;
            border-radius: 8px;
            background-color: var(--button-primary);
            border: none;
            box-shadow: 0px 3px 5px var(--border-input);
            color: var(--text-button-color);
            font-size: 15px;
            font-family: $fontMedium;
        }
    }
}