@import "../App.scss";

.popup-control {
    display: flex;

    .modal-dialog {
        height: 80% !important;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md) {
            .modal-dialog {
                max-width: 100%;
                margin: 0;
            }
        }

    }

    .modal-content {
        margin-top: auto !important;
        margin-bottom: auto !important;
        border: none !important;
        background-color: transparent !important;

        @include media-breakpoint-down(md) {
            .modal-content {
                background-color: transparent !important;
                margin: 10px;
            }
        }
    }

    .popup-img {
        width: 100%;
        cursor: pointer;
    }

    .popup-close {
        font-size: 16px !important;
        color: white !important;
        text-decoration: underline !important;
        text-align: center !important;
        font-family: $fontMedium;
        margin-top: 24px !important;
    }
}